import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {
    historiaWyczyscPrzyszlosc,
    wyznaczPrzyciskPrzod,
    wyznaczWybranaNazweKierunku,
    zapiszWyborOrazAktualnyPrzeplyw
} from "../utils/KalkulatorPrzeplywow"

const WyborKierunku = ({dksy, przeplywy, setPrzeplywy, studiaMenuRozwiniete, setStudiaMenuRozwiniete, podyplomoweMenuRozwiniete, setPodyplomoweMenuRozwiniete, podyplomoweSpecMenuRozwiniete, setPodyplomoweSpecMenuRozwiniete}) => {

    const idStrony = "ID_KIERUNEK"

    let { i18n, t } = useTranslation();
    let lang = i18n.language;
    historiaWyczyscPrzyszlosc(przeplywy, 1)

if(dksy == null) window.location.replace("/");

  const mapuj = (studia) => {

            let listaSplaszczona = studia
                .filter((item) => item.kierunekSpecjalnosc.kierunek.nazwa != null)
                .map((item) => item.kierunekSpecjalnosc.kierunek.nazwa);
            let mapa = new Map();
            let listaSplaszczonaBezPowtorzen = listaSplaszczona
                .filter((item, index) => listaSplaszczona.indexOf(item) === index)

            listaSplaszczonaBezPowtorzen.forEach(nazwa => {

                let vartosci = studia
                                .filter((it) => it.kierunekSpecjalnosc.kierunek.nazwa == nazwa);

                mapa.set(nazwa, vartosci);
                })

            let array = Array.from(mapa, ([nazwa, wartosc]) => ({ nazwa, wartosc }));

    return array;
    }

    const filtrujWgJezyka = (studia, lang) => {
        return studia.filter(item => lang === item.rekrutacja.lang)
    }

    let studiaWyzsze = dksy.filter(item => item.nabor.nazwaGrupy === ('STUDIA_WYZSZE'));
    let studiaWyzszePrzefiltrowaneWgJezyka = filtrujWgJezyka(studiaWyzsze, lang);
    let studiaWyzszePrzemapowane = mapuj(studiaWyzszePrzefiltrowaneWgJezyka);

    let podyplomowe = dksy.filter(item => item.nabor.nazwaGrupy === ('PODYPLOMOWE'));
    let podyplomowePrzefiltrowaneWgJezyka = filtrujWgJezyka(podyplomowe, lang);
    let podyplomowePrzemapowane = mapuj(podyplomowePrzefiltrowaneWgJezyka);

    let podyplomoweSpec = dksy.filter(item => item.nabor.nazwaGrupy === ('PODYPLOMOWE_SPEC'));
    let podyplomoweSpecPrzefiltrowaneWgJezyka = filtrujWgJezyka(podyplomoweSpec, lang);
    let podyplomoweSpecPrzemapowane = mapuj(podyplomoweSpecPrzefiltrowaneWgJezyka);

    const brakNaborow = () => {

        return studiaWyzszePrzemapowane.length == 0 && podyplomowePrzemapowane.length == 0 && podyplomoweSpecPrzemapowane.length == 0
    }

    let przyciskPrzod = wyznaczPrzyciskPrzod(przeplywy, 1)

    return (
        <>
        {brakNaborow() === true ?
        <>
            <div id="search">
                <div style={{clear: "both"}}>
                    <div className="title">{t("brak_naborow")}</div>
                </div>
            </div>
        </>
        :
        <>
            <div id="search">
                <img src={require("../images/flatlogowhite.png")} className="flatlogo"/>

                <div style={{clear: "both"}}>
                    <div className="title">{t("wybor.studiow.tytul")}</div>
                </div>




                {studiaWyzszePrzemapowane.length > 0 &&
                    <>
                    <div style={{clear: "both"}}></div>
                    <br/>
                    <div>
                        <h1
                            style={{textTransform: "uppercase", cursor: "pointer", width: "100%"}}
                            onClick = {(event) => setStudiaMenuRozwiniete(studiaMenuRozwiniete === false ? true : false)}>
                                {t("wybor.studiow.studia_lic_mgr")}
                                {studiaMenuRozwiniete === true ?
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_up.png")}/>
                                    :
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_down.png")}/>
                                }
                        </h1>

                    </div>
                    <div className="newline"></div>
                    <div className="sel">
                        {studiaMenuRozwiniete === true &&
                            <>
                                {studiaWyzszePrzemapowane
                                    .map(item => (
                                        <div
                                            style={{paddingTop: "25px", paddingBottom: "25px"}}
                                            className={"radio-style2"}
                                            onClick={(event) => zapiszWyborOrazAktualnyPrzeplyw(przeplywy, setPrzeplywy, item.wartosc, "STUDIA_WYZSZE", 1)}
                                        >
                                            <div className="dot">
                                            {item.nazwa == wyznaczWybranaNazweKierunku(przeplywy, 1) &&
                                                <div className="inner-dot"/>
                                            }
                                            </div>
                                            <div className="caption" style ={{width: "95%", float: "right"}}>
                                           {item.nazwa}
                                            </div>
                                        </div>
                                ))}

                            </>
                        }
                    </div>
                    </>
                }
                {przeplywy.aktualnyPrzeplywNazwa === "STUDIA_WYZSZE" &&
                    <Link to={przyciskPrzod.path} style={{ textDecoration: 'none' }}>
                        <div id="myid" style={{display: "block", marginTop: "20px"}}>
                            <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                                <button
                                    style={{width: "100%"}}
                                    className="buttongreen">
                                         {t(przyciskPrzod.nazwa)}
                                </button>
                            </div>
                        </div>
                     </Link>
                }




                {podyplomoweSpecPrzemapowane.length > 0 &&
                     <>
                        <div style={{clear: "both"}}></div>
                        <br/>
                        <h1
                            style={{textTransform: "uppercase", cursor: "pointer"}}
                            onClick = {(event) => setPodyplomoweSpecMenuRozwiniete(podyplomoweSpecMenuRozwiniete === false ? true : false)}>
                                {t("wybor.studiow.studia_podyplomowe")}
                                {podyplomoweSpecMenuRozwiniete === true ?
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_up.png")}/>
                                    :
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_down.png")}/>
                                }
                        </h1>
                        <div className="newline"></div>
                        <div className="sel">
                            {podyplomoweSpecMenuRozwiniete === true &&
                                <>
                                    {podyplomoweSpecPrzemapowane
                                        .map(item => (
                                        <div
                                            style={{paddingTop: "25px", paddingBottom: "25px"}}
                                            className={"radio-style2"}
                                            onClick={(event) => zapiszWyborOrazAktualnyPrzeplyw(przeplywy, setPrzeplywy, item.wartosc, "PODYPLOMOWE_SPEC", 1)}
                                        >
                                            <div className="dot">
                                            {item.nazwa == wyznaczWybranaNazweKierunku(przeplywy,1) &&
                                                <div className="inner-dot"/>
                                            }
                                            </div>
                                            <div className="caption" style ={{width: "95%", float: "right"}}>
                                           {item.nazwa}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                     </>
                }
                {przeplywy.aktualnyPrzeplywNazwa === "PODYPLOMOWE_SPEC" &&
                    <Link to={przyciskPrzod.path} style={{ textDecoration: 'none' }}>
                       <div id="myid" style={{display: "block", marginTop: "20px"}}>
                           <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                               <button
                                    style={{width: "100%"}}
                                    className="buttongreen">
                                    {t(przyciskPrzod.nazwa)}
                                </button>
                           </div>
                       </div>
                    </Link>
                }




                {podyplomowePrzemapowane.length > 0 &&
                    <>
                        <div style={{clear: "both"}}></div>
                        <br/>
                        <h1
                            style={{textTransform: "uppercase", cursor: "pointer"}}
                            onClick = {(event) => setPodyplomoweMenuRozwiniete(podyplomoweMenuRozwiniete === false ? true : false)}>
                                {t("wybor.studiow.studia_podyplomowe_inne")}
                                {podyplomoweMenuRozwiniete === true ?
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_up.png")}/>
                                    :
                                    <img style ={{width: "25px", height: "20px", float: "right"}} src={require("../images/sierzant_down.png")}/>
                                }
                        </h1>
                        <div className="newline"></div>
                        <div className="sel">
                            {podyplomoweMenuRozwiniete === true &&
                                <>
                                    {podyplomowePrzemapowane
                                        .map(item => (

                                        <div
                                            style={{paddingTop: "25px", paddingBottom: "25px"}}
                                            className={"radio-style2"}
                                            onClick={(event) => zapiszWyborOrazAktualnyPrzeplyw(przeplywy, setPrzeplywy, item.wartosc, "PODYPLOMOWE", 1)}
                                        >
                                            <div className="dot">
                                            {item.nazwa == wyznaczWybranaNazweKierunku(przeplywy, 1) &&
                                                <div className="inner-dot"/>
                                            }
                                            </div>
                                            <div className="caption" style ={{width: "95%", float: "right"}}>
                                           {item.nazwa}
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </div>
                    </>
                }
                {przeplywy.aktualnyPrzeplywNazwa === "PODYPLOMOWE" &&
                    <Link to={przyciskPrzod.path} style={{ textDecoration: 'none' }}>
                       <div id="myid" style={{display: "block", marginTop: "20px"}}>
                           <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                               <button
                                    style={{width: "100%"}}
                                    className="buttongreen">
                                    {t(przyciskPrzod.nazwa)}
                               </button>
                           </div>
                       </div>
                    </Link>
                }
            </div>
        </>
        }
        </>
        );
        }

export default WyborKierunku;
