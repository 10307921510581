import { Outlet, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18ne from '../i18n';
import { useParams } from "react-router";
import { wyznaczWybranaNazweKierunkuNaPodstawieIdStrony, wyznaczWybranaNazweSpecjalnosciNaPodstawieIdStrony, wyznaczWybranaNazweMiastaNaPodstawieIdStrony, wyznaczWybranaNazwePoziomuNaPodstawieIdStrony, wyznaczWybranaNazweTrybuNaPodstawieIdStrony} from "../utils/KalkulatorPrzeplywow"

const Layout = ({przeplywy, setPrzeplywy, pokarzFlagi, dksy}) => {

    const [darkTheme, setDarkTheme] = useState(false);
    const languages = [ "pl", "eng", "de", "sk", "cs", "ru"];
    let { i18n, t } = useTranslation();

    useEffect(() => {
        if(darkTheme === true){
            document.body.classList.add("dark-theme");
            localStorage.setItem("darkTheme", "true");
        }else{
            document.body.classList.remove("dark-theme");
            localStorage.setItem("darkTheme", "false");
        }
        // storing input name
    }, [darkTheme]);
    // useEffect(() => {
    //         localStorage.setItem("language", language);
    // }, [language]);


    let jezykiPobranychNaborow = [];
        dksy.forEach((definicje) => {
            let jezyk = definicje.rekrutacja.lang;
            if(languages.includes(jezyk)){
            jezykiPobranychNaborow.push(jezyk);
            }else{
            jezykiPobranychNaborow.push("?");
            }
        });
    const jezykiPobranychNaborowBezPowtorzen = Array.from(new Set(jezykiPobranychNaborow))

    let proponowanyJezyk;
    if(!jezykiPobranychNaborowBezPowtorzen.includes(i18n.language)){
        if(jezykiPobranychNaborowBezPowtorzen.includes("pl")) proponowanyJezyk = "pl";
        else if(jezykiPobranychNaborowBezPowtorzen.includes("eng")) proponowanyJezyk = "eng";
        else if(jezykiPobranychNaborowBezPowtorzen.includes("de")) proponowanyJezyk = "de";
        else if(jezykiPobranychNaborowBezPowtorzen.includes("sk")) proponowanyJezyk = "sk";
        else if(jezykiPobranychNaborowBezPowtorzen.includes("cs")) proponowanyJezyk = "cs";
        else if(jezykiPobranychNaborowBezPowtorzen.includes("ru")) proponowanyJezyk = "ru";

        if(i18n.language !== proponowanyJezyk) i18n.changeLanguage(proponowanyJezyk)
    }
    return (
        <>
            {pokarzFlagi === true &&
            <div className="mobileflagholder">
                {
                jezykiPobranychNaborowBezPowtorzen.filter(item => i18n.language !== item)
                        .map(item =>
                            <>
                                { item === "?" ?
                                    <img src={
                                        require("../images/unknownm.png")}
                                        className="flagimg"
                                    />
                                :
                                    <img src={
                                        require("../images/"+ item + "m.png")}
                                        className="flagimg"
                                        onClick={() => i18n.changeLanguage(item)
                                        .then(() => setPrzeplywy({
                                            aktualnyPrzeplywNazwa: null,
                                            historiaWyborow: new Map()
                                        }))}
                                    />
                                }
                            </>
                        )
                }
            </div>
            }
            <div className="mobilelogoholder">
                {darkTheme === true ?
                    <img src={require("../images/logomobilewhite.png")} id="logomob"/>
                    :
                    <img src={require("../images/logomobile.png")} id="logomob"/>
                }
                <br/>
                <div className="mobrek">{t("layout.tytul")}</div>
            </div>

            <div className="contmain">

                <div className="leftpanel">
                    <div className="leftpanelfixed">
                        {darkTheme === true ?
                            <img src={require("../images/logo.png")} className="titleimg" id="logomode"/>
                            :
                            <img src={require("../images/logoDark.png")} className="titleimg" id="logomode"/>
                        }
                        <img src={require("../images/changemode.png")} id="modeicon" onClick={() => setDarkTheme(darkTheme !== true)}/>
                        </div>
                </div>

                <Outlet/>

                <div className="rightpanelfixed">
                    {pokarzFlagi === true &&
                        <div style={{xDisplay:"none",float:"center",textAlign: "center", position: "absolute", right: "50px", width: "60%"}} className="header2">
                            {
                            jezykiPobranychNaborowBezPowtorzen.filter(item => i18n.language !== item)
                            .map(item =>
                                <>
                                    { item === "?" ?
                                        <img src={
                                            require("../images/unknown.png")}
                                            className="flagimg"
                                        />
                                    :
                                        <img
                                            src={require("../images/"+ item + ".png")}
                                            className="flagimg"
                                            onClick={() => i18n.changeLanguage(item)
                                            .then(() => setPrzeplywy({
                                                aktualnyPrzeplywNazwa: null,
                                                historiaWyborow: new Map()
                                            }))}
                                        />
                                    }
                                </>
                            )
                            }
                        <div style={{marginTop: "50px"}} className="rekel">{t("layout.tytul")}</div>
                    </div>
                    }


                    <br/>
                    <br/>
                    {(wyznaczWybranaNazweKierunkuNaPodstawieIdStrony(przeplywy) != null) &&
                    <>
                    <div className="selectedstudy">
                        <div className="selectedtext">{t("layout.wybrany.kierunek")}</div>
                        <Link
                            to="/"
                            style={{ textDecoration: 'none' }}>
                            <div style={{marginBottom: "15px"}}>
                                {wyznaczWybranaNazweKierunkuNaPodstawieIdStrony(przeplywy)}
                            </div>
                        </Link>
                        <div className="newline2" style={{marginTop: "15px", marginBottom: "15px"}}></div>
                        {(wyznaczWybranaNazweSpecjalnosciNaPodstawieIdStrony(przeplywy) != null) &&
                        <>
                            <Link
                                to="/wybor-specjalnosci"
                                style={{ textDecoration: 'none' }}>
                                <div className="selectedtext">{t("layout.wybrany.specjalnosc")}</div>
                                <div style={{marginBottom: "15px"}}>
                                    {wyznaczWybranaNazweSpecjalnosciNaPodstawieIdStrony(przeplywy)}
                                </div>
                            </Link>
                        </>
                        }
                        {(wyznaczWybranaNazwePoziomuNaPodstawieIdStrony(przeplywy) != null) &&
                        <>
                            <div className="selectedtext">{t("layout.wybrany.poziom")}</div>
                            <Link
                                to="/wybor-poziomu"
                                style={{ textDecoration: 'none' }}>
                                <div style={{marginBottom: "15px"}}>
                                    {wyznaczWybranaNazwePoziomuNaPodstawieIdStrony(przeplywy)}
                                </div>
                            </Link>
                        </>
                        }
                        {(wyznaczWybranaNazweTrybuNaPodstawieIdStrony(przeplywy) != null) &&
                        <>
                            <div className="selectedtext">{t("layout.wybrany.tryb")}</div>
                            <Link
                                to="/wybor-trybu"
                                style={{ textDecoration: 'none' }}>
                                <div style={{marginBottom: "15px"}}>
                                    {wyznaczWybranaNazweTrybuNaPodstawieIdStrony(przeplywy)}
                                </div>
                            </Link>
                         </>
                         }
                         {(wyznaczWybranaNazweMiastaNaPodstawieIdStrony(przeplywy) != null) &&
                         <>
                            <div className="selectedtext">{t("layout.wybrany.miasto")}</div>
                            <Link
                                to="/wybor-miasta"
                                style={{ textDecoration: 'none' }}>
                                <div style={{marginBottom: "15px"}}>
                                    {wyznaczWybranaNazweMiastaNaPodstawieIdStrony(przeplywy)}
                                </div>
                            </Link>
                        </>
                        }
                    </div>
                    </>
                    }

                    <div style={{marginTop:"150px"}} className="help-block">
                        <div style={{marginBottom:"10px"}}>{t("layout.pomoc")}</div>
                            {t("layout.zadzwon")}
                        <a href="tel:224875359" style={{textDecoration: "none"}}>
                            <div>{t("layout.telefon.kierunkowy_miedzynarodowy")} <span style={{fontSize: "2vw"}}>{t("layout.telefon.numer")}</span></div>
                        </a>
                    </div>
                    <br/>
                </div>
            </div>
        </>
    )
};

export default Layout;