import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import {
    czyDokonanoWyboru,
    historiaWyczyscPrzyszlosc,
    wyznaczDksy,
    wyznaczNumerStrony,
    wyznaczPrzyciskPrzod,
    wyznaczPrzyciskTyl,
    wyznaczWybranaNazweMiasta,
    zapiszWybor
} from "../utils/KalkulatorPrzeplywow"

const WyborStudiow = ({przeplywy, setPrzeplywy}) => {

    if(przeplywy.aktualnyPrzeplywNazwa == null) window.location.replace("/");

    let { i18n, t } = useTranslation();
    let lang = i18n.language;
    const idStrony = "ID_MIASTO"
    let numerStrony = wyznaczNumerStrony(przeplywy, idStrony)
    historiaWyczyscPrzyszlosc(przeplywy, numerStrony)
    let dksy = wyznaczDksy(przeplywy, numerStrony-1)
    let przyciskPrzod = wyznaczPrzyciskPrzod(przeplywy, numerStrony)
    let przyciskTyl = wyznaczPrzyciskTyl(przeplywy, numerStrony)



  const mapuj = (studia) => {
            let listaSplaszczona = studia
                .filter((item) => item.kierunekSpecjalnosc.wydzial.nazwa != null)
                .map((item) => item.kierunekSpecjalnosc.wydzial.nazwa);
            let mapa = new Map();
            let listaSplaszczonaBezPowtorzen = listaSplaszczona
                .filter((item, index) => listaSplaszczona.indexOf(item) === index)

            listaSplaszczonaBezPowtorzen.forEach(nazwa => {

                let vartosci = studia
                                .filter((it) => it.kierunekSpecjalnosc.wydzial.nazwa == nazwa);
                mapa.set(nazwa, vartosci);
                })

            let array = Array.from(mapa, ([nazwa, wartosc]) => ({ nazwa, wartosc }));

    return array;
    }

    let przemapowaneNabory = mapuj(dksy);


    return (
         <>
            <div id="search">
                <img src={require("../images/flatlogowhite.png")} className="flatlogo"/>

                <div style={{clear: "both"}}>
                    <div className="title">{t("wybor.miasta.tytul")}</div>
                </div>

                <div style={{clear: "both"}}></div>
                <br/>
                <div className="newline"></div>

            {przemapowaneNabory.length > 0 &&
                <>
                    <h1 style={{textTransform: "uppercase"}}>{t("wybor.miasta.miasto")}:  </h1>
                    <div style={{clear: "both"}}></div>
                        <br/>
                        <div className="sel">
                        {przemapowaneNabory
                            .map(item => (

                                <div
                                    style={{paddingTop: "25px", paddingBottom: "25px"}}
                                    className={"radio-style2"}
                                    onClick={(event) => zapiszWybor(przeplywy, setPrzeplywy, item.wartosc, numerStrony)}
                                >
                                    <div className="dot">
                                    {item.nazwa == wyznaczWybranaNazweMiasta(przeplywy, numerStrony) &&
                                        <div className="inner-dot"/>
                                    }
                                    </div>
                                    <div className="caption" style ={{width: "95%", float: "right"}}>
                                   {item.nazwa}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
                }

                    <Link to={przyciskTyl.path} style={{ textDecoration: 'none' }}>
                        <div id="myid" style={{display: "block", marginTop: "20px"}}>
                            <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                                <button style={{width: "100%"}} className="buttongrey">{t("wybor.miasta.przycisk.powrot")}</button>
                            </div>
                        </div>
                    </Link>
                {czyDokonanoWyboru(przeplywy, numerStrony) &&
                        <Link to={przyciskPrzod.path} style={{ textDecoration: 'none' }}>
                            <div id="myid" style={{display: "block", marginTop: "20px"}}>
                                <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                                    <button style={{width: "100%"}} className="buttongreen">
                                    {t(przyciskPrzod.nazwa)}
                                    </button>
                                </div>
                            </div>
                        </Link>
                 }
                </div>
                </>
        );
        }

export default WyborStudiow;
