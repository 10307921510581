import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import DatePicker , { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DaneOsoboweComponent from "../components/podsumowanie/DaneOsoboweComponent";
import WyborTrybu from "./WyborTrybu";
import "../css/custom-date-picker.css";
import pl from "date-fns/locale/pl";
import de from "date-fns/locale/de";
import sk from "date-fns/locale/sk";
import cs from "date-fns/locale/cs";
import ru from "date-fns/locale/ru";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { wyznaczDksy, wyznaczNumerStronyPodsumowanie } from "../utils/KalkulatorPrzeplywow";
import rest from '../utils/RestClient';
import { wyznaczElementyPozostale, wyznaczElementDomyslny } from '../utils/PodsumowanieFunkcje';
import PodsumowanieAdres from './components/PodsumowanieAdres';

const Podsumowanie = ({przeplywy, promoLink}) => {

    if(przeplywy.aktualnyPrzeplywNazwa == null) window.location.replace("/");

    let numerStrony = wyznaczNumerStronyPodsumowanie(przeplywy)
    let dks = wyznaczDksy(przeplywy, numerStrony-1)
     if(dks.length > 1) alert("Znaleziono wiecej niz jeden dks!");
    const navigate = useNavigate();

    const [trybWalidacyjny, setTrybWalidacyjny] = useState(false);
    const [slowniki, setSlowniki] =  useState(null);

    const [zgodyPokarzSzczegoly, setZgodyPokarzSzczegoly] = useState(false)
    const [formularzeOrazZgody, setFormularzeOrazZgody] =  useState(null);

    const [imieKandydata, setImieKandydata] = useState({wartosc: ""});
    const [imie2Kandydata, setImie2Kandydata] = useState({wartosc: ""});
    const [nazwiskoKandydata, setNazwiskoKandydata] = useState({wartosc: ""});
    const [peselKandydata, setPeselKandydata] = useState({wartosc: ""});
    const [dataUrodzeniaKandydata, setDataUrodzeniaKandydata] =  useState({wartosc: "", min: 1});
    const [miejsceUrodzeniaKandydata, setMiejsceUrodzeniaKandydata] = useState({wartosc: ""});
    const [plecKandydata, setPlecKandydata] = useState({wartosc: ""});
    const [obywatelstwoKandydata, setObywatelstwoKandydata] = useState({wartosc: ""});
    const [krajPochodzeniaKandydata, setKrajPochodzeniaKandydata] = useState({wartosc: ""});
    const [emailKandydata, setEmailKandydata] = useState({wartosc: ""});
    const [numerKierunkowyKandydata, setNumerKierunkowyKandydata] = useState({wartosc: ""});
    const [telefonKandydata, setTelefonKandydata] = useState({wartosc: ""});

    const [obcokrajowiec, setObcokrajowiec] = useState(false);
    const [obcokrajowiecPesel, setObcokrajowiecPesel] = useState(false);
    const [obcokrajowiecRodzajDowodu, setObcokrajowiecRodzajDowodu] = useState({wartosc: ""});
    const [obcokrajowiecSeriaNumerDowodu, setObcokrajowiecSeriaNumerDowodu] = useState({wartosc: ""});
    const [wydanieEls, setWydanieEls] = useState(false);
    const [korespondencyjnyJakMiejsceZamieszkania, setKorespondencyjnyJakMiejsceZamieszkania] = useState(true);

    let { i18n, t } = useTranslation();
    let lang = i18n.language;

    let wybranyJezykDlaDatePicker = null

    if(lang == "pl"){
     registerLocale("pl", pl);
     wybranyJezykDlaDatePicker = pl
    }
    if(lang == "de"){
     registerLocale("de", de);
      wybranyJezykDlaDatePicker = de
    }
    if(lang == "sk"){
     registerLocale("sk", sk);
      wybranyJezykDlaDatePicker = sk
    }
    if(lang == "cs"){
     registerLocale("cs", cs);
      wybranyJezykDlaDatePicker = cs
    }
    if(lang == "ru"){
     registerLocale("ru", ru);
      wybranyJezykDlaDatePicker =ru
    }

    if(obcokrajowiec === false || obcokrajowiecPesel === true){
        if(peselKandydata.ostatniaPoprawnaWyliczonaDataUrodzenia != null){

            let date = peselKandydata.ostatniaPoprawnaWyliczonaDataUrodzenia;
            let my_date = new Date(date.split("-").reverse().join("-"));
            dataUrodzeniaKandydata.wartosc = my_date;
        }
    }

    const parsujPeselDoDatyUrodzin = (pesel) => {


                        var s = pesel;
                        if (s.length != 11) return null;
                        var aInt = new Array();
                        for (let i = 0; i < 11; i++) {
                            aInt[i] = parseInt(s.substring(i, i + 1));
                        }

                        var wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
                        var sum = 0;
                        for (let i = 0; i < 11; i++)
                            sum += wagi[i] * aInt[i];
                        if ((sum % 10) != 0) return null;
                        var rok = 1900 + aInt[0] * 10 + aInt[1];
                        if (aInt[2] >= 2 && aInt[2] < 8)
                            rok += Math.floor(aInt[2] / 2) * 100;
                        if (aInt[2] >= 8)
                            rok -= 100;
                        var miesiac = (aInt[2] % 2) * 10 + aInt[3];
                        var dzien = aInt[4] * 10 + aInt[5];
//                        if (SetError(!checkDate(dzien, miesiac, rok)))
//                            return;

                        if (miesiac.toString().length == 1) {
                            miesiac = '0' + miesiac;
                        }
                        if (dzien.toString().length == 1) {
                            dzien = '0' + dzien;
                        }
                        return (dzien + '-' + miesiac + '-' + rok);
                    }


    const oznaczOdpowiedzNaDefinicjachFormularzy = (odpowiedz) => {

        let formularzeOrazZgodyKopia = JSON.parse(JSON.stringify(formularzeOrazZgody));
        for (const definicjaFormularza of formularzeOrazZgodyKopia.definicjeFormularzy) {
            for (const formularz of definicjaFormularza.pozycjeFormularza) {

                if(odpowiedz.formularzId === formularz.id){
//                formularz.odpowiedz = odpowiedz.odpowiedzNazwa;

                                formularz.odpowiedz = {
                                odpowiedzNazwa: odpowiedz.odpowiedzNazwa,
                                odpowiedzId: odpowiedz.odpowiedzId,
                                odpowiedzTyp: odpowiedz.odpowiedzTyp,
                                formularzTyp: odpowiedz.formularzTyp
                                }
                }
            }
        }
        setFormularzeOrazZgody(formularzeOrazZgodyKopia);
    }

    const oznaczOdpowiedzNaWarunkachRekrutacyjnych = (odpowiedz) => {

        let formularzeOrazZgodyKopia = JSON.parse(JSON.stringify(formularzeOrazZgody));
            for (const warunekRekrutacyjny of formularzeOrazZgodyKopia.warunkiRekrutacyjne) {
              if(odpowiedz.warunekRekrutacyjnyId === warunekRekrutacyjny.id){
                  warunekRekrutacyjny.zgoda = odpowiedz.zgoda;
              }
            }
        setFormularzeOrazZgody(formularzeOrazZgodyKopia);
    }

    const oznaczOdpowiedzNaWszystkichWarunkachRekrutacyjnych = (odpowiedz) => {

        let formularzeOrazZgodyKopia = JSON.parse(JSON.stringify(formularzeOrazZgody));
            for (const warunekRekrutacyjny of formularzeOrazZgodyKopia.warunkiRekrutacyjne) {
                  warunekRekrutacyjny.zgoda = odpowiedz.zgoda;
            }
        setFormularzeOrazZgody(formularzeOrazZgodyKopia);
    }

    const czyBlednaWartosc = (pole) =>{
    if(trybWalidacyjny === false) return false;
    if(pole.min == null) return true;
    return pole.wartosc.length < pole.min;
    }

    const czyBlednyPesel = (pesel) =>{
        if(trybWalidacyjny === false) return false;
        if(pesel.length !== 11) return true;
        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(pesel.substring(10, 11));

        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        return (10 - sum) % 10 !== controlNumber;
    }

        const czyBlednyPeselBezWalidacji = (pesel) =>{
            if(pesel.length == 1) return false;
            if(pesel.length !== 11) return true;
            let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
            let sum = 0;
            let controlNumber = parseInt(pesel.substring(10, 11));

            for (let i = 0; i < weight.length; i++) {
                sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
            }
            sum = sum % 10;
            return (10 - sum) % 10 !== controlNumber;
        }



    const czyFormularzTypuTakNie = (formularz) => {

    if(formularz != null && formularz.odpowiedzi != null && formularz.odpowiedzi.length === 2 ){

        let pierwszaPozycja = formularz.odpowiedzi[0].nazwa;
        let drugaPozycja = formularz.odpowiedzi[1].nazwa;
        if((pierwszaPozycja.toUpperCase() === "TAK" && drugaPozycja.toUpperCase() === "NIE") || (pierwszaPozycja.toUpperCase() === "NIE" && drugaPozycja.toUpperCase() === "TAK")){
            return true;
        }
    }
    return false;
    }

    const czyFormularzJednaOdpowiedz = (formularz) => {
        if(formularz != null && formularz.odpowiedzi != null && formularz.odpowiedzi.length === 1 ){
            return true;
        }
        return false;
    }

    const zbierzDaneDokonajWalidacjiWyslijRestem = () => {

     let flaga = false;

      const czyBlednaWartoscZmiennej = (pole) =>{

        if(pole.min == null) return true;
        return pole.wartosc.length < pole.min;
        }

      const czyBlednaWartoscZmiennejHtml = (pole) =>{

        let htmlElement = document.getElementById(pole.wartosc)

        if(htmlElement.value == null) return true
        if(htmlElement.value.length < pole.min) return true
        if(htmlElement.value.endsWith("*")) return true
        return false;
      }
//
      if(!obcokrajowiec){
            if (czyBlednaWartoscZmiennej(peselKandydata) || czyBlednyPesel(peselKandydata.wartosc))  setTrybWalidacyjny(true);
      }else{
            if(obcokrajowiecPesel === true){
                if (czyBlednaWartoscZmiennej(peselKandydata) || czyBlednyPesel(peselKandydata.wartosc))  setTrybWalidacyjny(true);
                if (obcokrajowiecRodzajDowodu.wartosc.length != 0 && czyBlednaWartoscZmiennej(obcokrajowiecSeriaNumerDowodu)) setTrybWalidacyjny(true);
            }else{
                if (czyBlednaWartoscZmiennej(obcokrajowiecRodzajDowodu) || czyBlednaWartoscZmiennej(obcokrajowiecSeriaNumerDowodu)) setTrybWalidacyjny(true);
            }
      }

     if (dataUrodzeniaKandydata.wartosc.length == 0 ) setTrybWalidacyjny(true);

      [imieKandydata, nazwiskoKandydata, dataUrodzeniaKandydata, miejsceUrodzeniaKandydata,plecKandydata]
      .forEach(item =>{
        if (czyBlednaWartoscZmiennej(item))  flaga = true;
        });

        [{wartosc: "zamieszkania_kraj", min: 3}, {wartosc: "zamieszkania_ulica", min: 3}, {wartosc: "zamieszkania_kod_pocztowy", min: 3}, {wartosc: "zamieszkania_miasto", min: 3}, {wartosc: "zamieszkania_rodzaj_miejscowosci", min: 1}, {wartosc: "zamieszkania_numer_domu", min: 1}]
            .forEach(item =>{ console.log(item);
             if (czyBlednaWartoscZmiennejHtml(item))  flaga = true;  console.log("flaga " +flaga );
              });
 console.log("flaga " +flaga );
//zamieszkania_wojewodztwo

        if(korespondencyjnyJakMiejsceZamieszkania !== true){
             [{wartosc: "korespondencyjny_kraj", min: 3}, {wartosc: "korespondencyjny_ulica", min: 3}, {wartosc: "korespondencyjny_kod_pocztowy", min: 3}, {wartosc: "korespondencyjny_miasto", min: 3}, {wartosc: "korespondencyjny_rodzaj_miejscowosci", min: 1}, {wartosc: "korespondencyjny_numer_domu", min: 1}]
                .forEach(item =>{
                  if (czyBlednaWartoscZmiennejHtml(item))  flaga = true;
                  });
        }
        if(czyBlednaWartoscZmiennej(emailKandydata))    flaga = true;
        if(czyBlednaWartoscZmiennej(telefonKandydata))  flaga = true;

        formularzeOrazZgody.definicjeFormularzy
        .forEach(item =>{

            for (let i = 0; i < item.pozycjeFormularza.length; i++) {

                let odpowiedziano = false;
                let ignoruj = false;

                if(item.pozycjeFormularza[i].odpowiedz == null && item.pozycjeFormularza[i].wymagane === true){
                            console.log(item.pozycjeFormularza[i])
                          flaga = true;
                }
            }
        })
         formularzeOrazZgody.warunkiRekrutacyjne
          .forEach(item =>{
            if (item.zgoda == null){
             flaga = true;
            }
          })

        if(flaga  === false){

        let zamieszkania_wojewodztwo_final  =document.getElementById("zamieszkania_wojewodztwo") == null ? "" : document.getElementById("zamieszkania_wojewodztwo").value
        let korespondencyjny_wojewodztwo_final  =document.getElementById("korespondencyjny_wojewodztwo") == null ? "" : document.getElementById("korespondencyjny_wojewodztwo").value

        document.getElementById("finalButton").classList.add('buttongreyfinal');
        document.getElementById("finalButton").classList.remove('buttongreen');
        let nrKierunkowy = document.getElementById("numerKierunkowyCombobox").value;

        let adresZmLokalna = {
                     adres: document.getElementById("zamieszkania_ulica").value,
                     numerDomu: document.getElementById("zamieszkania_numer_domu").value,
                     kodPocztowy: document.getElementById("zamieszkania_kod_pocztowy").value,
                     miejscowosc: document.getElementById("zamieszkania_miasto").value,
                     rodzajMiejscowosci: document.getElementById("zamieszkania_rodzaj_miejscowosci").value,
                     wojewodztwo: zamieszkania_wojewodztwo_final,
                     kraj: document.getElementById("zamieszkania_kraj").value
        };

        let adresKontaktowyZmLokalna = {
                     adres: document.getElementById("korespondencyjny_ulica") == null ? "" :document.getElementById("korespondencyjny_ulica").value,
                     numerDomu: document.getElementById("korespondencyjny_numer_domu") == null ? "" :document.getElementById("korespondencyjny_numer_domu").value,
                     kodPocztowy: document.getElementById("korespondencyjny_kod_pocztowy") == null ? "" :document.getElementById("korespondencyjny_kod_pocztowy").value,
                     miejscowosc: document.getElementById("korespondencyjny_miasto") == null ? "" :document.getElementById("korespondencyjny_miasto").value,
                     rodzajMiejscowosci: document.getElementById("korespondencyjny_rodzaj_miejscowosci") == null ? "" :document.getElementById("korespondencyjny_rodzaj_miejscowosci").value,
                     wojewodztwo: korespondencyjny_wojewodztwo_final,
                     kraj: document.getElementById("korespondencyjny_kraj") == null ? "" :document.getElementById("korespondencyjny_kraj").value
                };

        let krajPochodzenia_final  =document.getElementById("kraj_pochodzenia") == null ? "" : document.getElementById("kraj_pochodzenia").value
        let obywatelstwo_final  =document.getElementById("obywatelstwo") == null ? "" : document.getElementById("obywatelstwo").value

        let finalnyObiekt = {
            kandydat: {
                imie: imieKandydata.wartosc,
                imie2: imie2Kandydata.wartosc === "" ? null : imie2Kandydata.wartosc,
                nazwisko: nazwiskoKandydata.wartosc,
                pesel: peselKandydata == "" ? null : peselKandydata.wartosc,
                obcokrajowiecRodzajDowodu: (obcokrajowiec === true && obcokrajowiecRodzajDowodu === "") ? null : obcokrajowiecRodzajDowodu.wartosc,
                obcokrajowiecSeriaNumerDowodu: (obcokrajowiec === true && obcokrajowiecSeriaNumerDowodu === "") ? null : obcokrajowiecSeriaNumerDowodu.wartosc,
                dataUrodzenia: dataUrodzeniaKandydata.wartosc,
                miejsceUrodzenia: miejsceUrodzeniaKandydata.wartosc,
                plec: plecKandydata.wartosc,
                obywatelstwo: obywatelstwo_final,
                krajPochodzenia: krajPochodzenia_final,
                adres: adresZmLokalna,
                adresKontaktowy: korespondencyjnyJakMiejsceZamieszkania === true ? null : adresKontaktowyZmLokalna,
                adresKorespondencyjnyJakZamieszkania: korespondencyjnyJakMiejsceZamieszkania,
                email: emailKandydata.wartosc,
                telefon: telefonKandydata.wartosc,
//                nrKierunkowy: nrKierunkowy,
                obcokrajowiec: obcokrajowiec
                },

            wydanieEls: wydanieEls,
            warunkiRekrutacyjne: formularzeOrazZgody.warunkiRekrutacyjne,
            definicjeFormularzy: formularzeOrazZgody.definicjeFormularzy,
            promoLink: promoLink,
            wybranyNabor: dks[0]
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(finalnyObiekt)
            };
           fetch(rest+'kandydaci', requestOptions)

                .then(response => {

                    if (response.status == 200){
                         navigate('/zakonczenie');
                    }
                    else if(response.status == 400){
                        response.json()
                        .then(function(resp){
                        console.log(resp)
                        if(resp.kod === "KANDYDAT_ZAPISANY_WCZESNIEJ_NA_WYBRANY_KURS")
                        toast(t("podsumowanie.toast.kandydat_zapisany_wczesniej"));
                        })
                    }
                    else{
                       console.log(response.json())
                    }
                })
        }else{
            setTrybWalidacyjny(true)}
    }




    let wybranyNabor = dks[0];
    let wybranyKierunek = wybranyNabor.kierunekSpecjalnosc

     if(formularzeOrazZgody == null){


            Promise.all([
             fetch(rest+'definicje-formularzy/kierunek-specjalnosc/'+wybranyNabor.kierunekSpecjalnosc.id),
              fetch(rest+'warunki-rekrutacyjne/rekrutacja/'+wybranyNabor.rekrutacja.id + '?lang=' + lang)
            ])
            .then(([resDefinicjeFormularzy, resWarunkiRekrutacyjne]) =>
                    Promise.all([resDefinicjeFormularzy.json(), resWarunkiRekrutacyjne.json()])
                  )
            .then(([definicjeFormularzy, warunkiRekrutacyjne]) => {
                setFormularzeOrazZgody({
                    definicjeFormularzy: definicjeFormularzy,
                    warunkiRekrutacyjne: warunkiRekrutacyjne
                    });
                })


    }else if(slowniki == null){
                Promise.all([
                  fetch(rest+'slowniki/kraj?lang='+lang),
                  fetch(rest+'slowniki/obywatelstwo?lang='+lang),
                  fetch(rest+'slowniki/numer-kierunkowy?lang='+lang)
                ])
                .then(([resKraje, resObywatelstwa, resNumeryKierunkowe]) =>
                        Promise.all([resKraje.json(), resObywatelstwa.json(), resNumeryKierunkowe.json()])
                      )
                .then(([kraje, obywatelstwa, numeryKierunkowe]) => {
                    setSlowniki({
                        kraje: kraje,
                        obywatelstwa: obywatelstwa,
                        numeryKierunkowe: numeryKierunkowe
                        });
                    })
    }

    let definicjeFormularzy =  formularzeOrazZgody == null ? null : formularzeOrazZgody.definicjeFormularzy;
    let warunkiRekrutacyjne =  formularzeOrazZgody == null ? null : formularzeOrazZgody.warunkiRekrutacyjne;
    let defaultowyNumerKierunkowy = (slowniki == null || slowniki.numeryKierunkowe == null) ? {kodKierunkowy: null, wartosc: null} : slowniki.numeryKierunkowe[0]

    let krajDomyslny = wyznaczElementDomyslny(slowniki?.kraje)
    let krajePozostale = wyznaczElementyPozostale(slowniki?.kraje)
    let obywatelstwoDomyslne = wyznaczElementDomyslny(slowniki?.obywatelstwa)
    let obywatelstwaPozostale = wyznaczElementyPozostale(slowniki?.obywatelstwa)
    return (
         <>
         <ToastContainer />
         <div id="search">
             <img src={require("../images/flatlogowhite.png")} className="flatlogo"/>
             <div>
                 <div className="title">{t("podsumowanie.tytul")}</div>
             </div>
             <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.wybrane_studia.tytul")}</h1>
             <div className="newline"></div>
                <div className="wybrane">
                <b>{t("podsumowanie.wybrane_studia.poziom")}: </b> {wybranyKierunek.stopienStudiow.nazwa}
                <br/>
                <b>{t("podsumowanie.wybrane_studia.tryb")}: </b>{wybranyKierunek.trybStudiow.nazwa}
                <br/>
                <b>{t("podsumowanie.wybrane_studia.kierunek")}: </b>{wybranyKierunek.kierunek.nazwa}
                <br/>
                <b>{t("podsumowanie.wybrane_studia.specjalnosc")}: </b>{wybranyKierunek.specjalnosc.nazwa}
                <br/>
                <b>{t("podsumowanie.wybrane_studia.miasto")}: </b>{wybranyKierunek.wydzial.nazwa}<br/>
                </div>
             <div>
                <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.obywatelstwo.tytul")}</h1>
                <div className="newline"></div>
                <div className="grid">
                    <div className="row">
                        <div className="cell-6">
                            <label className="radio style2 required">
                                <input onClick={(event) => setObcokrajowiec(false)} type="radio" checked={!obcokrajowiec}/>
                                <div className="check"></div>
                                <div className="caption">{t("podsumowanie.obywatelstwo.polskie")}</div>
                            </label>
                        </div>
                        <div className="cell-6">
                            <label className="radio style2 required">
                                <input onClick={(event) => setObcokrajowiec(true)} type="radio" checked={obcokrajowiec}/>
                                <div className="check"></div>
                                <div className="caption">{t("podsumowanie.obywatelstwo.obcokrajowiec")}</div>
                                </label>
                        </div>
                    </div>
                </div>
             </div>
             <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.m_legitymacja.tytul")}</h1>
             <div className="newline"></div>
             <div className="xform-group">
             <label className="checkbox style2">
                <input type="checkbox" onClick={(event) => wydanieEls === true ? setWydanieEls(false) : setWydanieEls(true)} checked={wydanieEls}/>
                <span className="check"></span>
                <div className="caption">
                    <div style={{lineHeight: "20px"}}>{t("podsumowanie.m_legitymacja.wydanie")}</div>
                </div>
             </label>
         </div>
         <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.dane_osobowe.tytul")}</h1>



         <div className="newline"></div>
            <div className="row">
                <div className="cell-md-6">
                    <div className="form-group">
                        <input type="text" id="imie_input" placeholder={t("podsumowanie.dane_osobowe.imie") + "*"} className="inputstyle required"
                            onChange={(event) => setImieKandydata({wartosc: event.target.value, min: 3})}/>
                            {czyBlednaWartosc(imieKandydata) &&
                                <span className="error_panel" id="imie_input_span">{t("podsumowanie.dane_osobowe.walidacja.imie")}</span>
                            }
                    </div>
                </div>
                <div className="cell-md-6">
                    <div className="form-group">
                        <input type="text" id="imie2_input" placeholder={t("podsumowanie.dane_osobowe.imie_drugie")} className="inputstyle required"
                            onChange={(event) => setImie2Kandydata({wartosc: event.target.value})}/>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="cell-md-12">
                    <div className="form-group">
                        <input type="text" name="SurName" placeholder={t("podsumowanie.dane_osobowe.nazwisko") + "*"} className="inputstyle required"
                        onChange={(event) => setNazwiskoKandydata({wartosc: event.target.value, min: 3})}/>
                        {czyBlednaWartosc(nazwiskoKandydata) &&
                            <span className="error_panel">{t("podsumowanie.dane_osobowe.walidacja.nazwisko")}</span>
                        }
                    </div>
                </div>
            </div>
            {obcokrajowiec === true &&
                <div className="grid">
                    <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.obcokrajowiec.pesel.nazwa")}</h1>
                                    <div className="newline"></div>
                    <div className="row">
                        <div className="cell-6">
                            <label className="radio style2 required">
                                <input onClick={(event) => setObcokrajowiecPesel(true)} type="radio" checked={obcokrajowiecPesel}/>
                                <div className="check"></div>
                                <div className="caption">{t("podsumowanie.obcokrajowiec.pesel.tak")}</div>
                            </label>
                        </div>
                        <div className="cell-6">
                            <label className="radio style2 required">
                                <input onClick={(event) => setObcokrajowiecPesel(false)} type="radio" checked={!obcokrajowiecPesel}/>
                                <div className="check"></div>
                                <div className="caption">{t("podsumowanie.obcokrajowiec.pesel.nie")}</div>
                            </label>
                        </div>
                    </div>
                </div>
            }
            {(obcokrajowiec === false || obcokrajowiecPesel === true)  &&
                <div className="row">
                    <div className="cell-md-12">
                        <div className="form-group">
                            <input type="text" placeholder={t("podsumowanie.dane_osobowe.pesel") + "*"} className="inputstyle required "
                            value={peselKandydata.wartosc}
                            onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                            onChange={(event) => {
                                let peselVal = event.target.value

                                let sparsowanaDataUrodzin = parsujPeselDoDatyUrodzin(peselVal);

                                let ostatniaPoprawnaWyliczonaDataUrodzenia = sparsowanaDataUrodzin != null ? sparsowanaDataUrodzin : peselKandydata.ostatniaPoprawnaWyliczonaDataUrodzenia;
                                setPeselKandydata({
                                    wartosc: event.target.value,
                                    ostatniaPoprawnaWyliczonaDataUrodzenia: ostatniaPoprawnaWyliczonaDataUrodzenia,
                                    min: 11})

                                }}/>
                            {(czyBlednyPesel(peselKandydata.wartosc) || (czyBlednyPeselBezWalidacji(peselKandydata.wartosc) && peselKandydata.wartosc.length > 0)) &&
                                   <span className="error_panel">
                                        {t("podsumowanie.dane_osobowe.walidacja.pesel_bledny")}
                                 </span>
                            }
                        </div>
                    </div>
                </div>
            }
            {obcokrajowiec === true &&
                <>
                    <div className="row">
                       <div className="cell-md-12">
                           <div className="xform-group">
                               <select xxdata-role="select" className="inputstyle" onChange={(event) => setObcokrajowiecRodzajDowodu({wartosc: event.target.value, min: 1})}>
                                   <option value="" selected="" disabled="" hidden="">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.tytul") + "*"}</option>
                                   <option value="dowod_osobisty">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.dowod_osobisty")}</option>
                                   <option value="paszport">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.paszport")}</option>
                                   <option value="karta_pobytu">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.karta_pobytu")}</option>
                                   <option value="polski_dokument_podrozy">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.polski_dokument_podrozy")}</option>
                                   <option value="tymczasowy_polski_dokument_podrozy">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.tymczasowy_polski_dokument_podrozy")}</option>
                                   <option value="polski_dokument_tozsamosci_cudzoziemca">{t("podsumowanie.obcokrajowiec.rodzaj_dowodu.polski_dokument_tozsamosci_cudzoziemca")}</option>
                               </select>

                                {(czyBlednaWartosc(obcokrajowiecRodzajDowodu) && obcokrajowiecPesel === false) &&
                                   <span className="error_panel">
                                       {t("podsumowanie.obcokrajowiec.walidacja.rodzaj_dowodu")}
                                   </span>
                                }
                           </div>
                       </div>
                    </div>
                    <div className="row">
                        <div className="cell-md-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder={t("podsumowanie.obcokrajowiec.seria_numer_dowodu") + "*"}
                                    onChange={(event) => setObcokrajowiecSeriaNumerDowodu({wartosc: event.target.value, min: 3})}
                                    className="inputstyle"/>
                                {((obcokrajowiecPesel === true && obcokrajowiecRodzajDowodu.wartosc.length > 0 && czyBlednaWartosc(obcokrajowiecSeriaNumerDowodu)) ||
                                obcokrajowiecPesel === false && czyBlednaWartosc(obcokrajowiecSeriaNumerDowodu)) &&
                                    <span className="error_panel">
                                        {t("podsumowanie.obcokrajowiec.walidacja.seria_numer_dowodu")}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="row">
                <div className="cell-md-12">
                    <div className="form-group">

                    <DatePicker
                        locale={wybranyJezykDlaDatePicker}
                        showYearDropdown
                        selected={dataUrodzeniaKandydata.wartosc}
                        onChange={(date) => setDataUrodzeniaKandydata({wartosc: date, min: 1 })}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        maxDate={new Date()}
                        placeholderText={t("podsumowanie.dane_osobowe.data_ur") + "*"}
                        dateFormat="dd/MM/yyyy"
                        className="inputstyle customDatePickerWidth"
                        disabled={obcokrajowiec === false || obcokrajowiecPesel === true}
                    />

                    </div>
                    <div className="form-group">
                    {czyBlednaWartosc(dataUrodzeniaKandydata) &&
                         <span className="error_panel">
                                {t("podsumowanie.dane_osobowe.walidacja.data_ur")}
                         </span>
                    }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="cell-md-12">
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder={t("podsumowanie.dane_osobowe.miejsce_ur") + "*"}
                            onChange={(event) => setMiejsceUrodzeniaKandydata({wartosc: event.target.value, min: 3})}
                            className="inputstyle"/>
                        {czyBlednaWartosc(miejsceUrodzeniaKandydata) &&
                            <span className="error_panel">
                                {t("podsumowanie.dane_osobowe.walidacja.miejsce_ur")}
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="cell-md-12">
                    <div className="xform-group">
                        <select xxdata-role="select" className="inputstyle" onChange={(event) => setPlecKandydata({wartosc: event.target.value, min: 1})}>
                            <option selected="" disabled="" hidden="">{t("podsumowanie.dane_osobowe.plec.tytul") + "*"}</option>
                            <option value="Kobieta">{t("podsumowanie.dane_osobowe.plec.kobieta")}</option>
                            <option value="Mężczyzna">{t("podsumowanie.dane_osobowe.plec.mezczyzna")}</option>
                        </select>
                         {czyBlednaWartosc(plecKandydata) &&
                            <span className="error_panel">
                                {t("podsumowanie.dane_osobowe.walidacja.plec")}
                            </span>
                         }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="cell-md-12">
                    <div className="xform-group">
                        <select id="obywatelstwo" xdata-role="select" onChange={(event) => setObywatelstwoKandydata({wartosc: event.target.value, min: 1})} className="inputstyle">
                            <option
                                selected=
                                    {obywatelstwoDomyslne == null ?
                                    t("podsumowanie.dane_osobowe.obywatelstwo") + "*"
                                    :
                                    obywatelstwoDomyslne.nazwa}
                                disabled=""
                                hidden="">
                                    {obywatelstwoDomyslne == null ?
                                    t("podsumowanie.dane_osobowe.obywatelstwo") + "*"
                                    :
                                    obywatelstwoDomyslne.wartosc}
                            </option>
                            {slowniki &&
                                <>
                                    {
                                        obywatelstwaPozostale
                                        .map(item => (<option value={item.nazwa}>{item.wartosc}</option>))
                                    }
                                </>
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="cell-md-12">
                    <div className="xform-group">
                        <select id="kraj_pochodzenia" xdata-role="select" onChange={(event) => setKrajPochodzeniaKandydata({wartosc: event.target.value, min: 1})} className="inputstyle">
                            <option
                                selected=
                                    {krajDomyslny == null ?
                                    t("podsumowanie.dane_osobowe.kraj_pochodzenia") + "*"
                                    :
                                    krajDomyslny.nazwa}
                                disabled=""
                                hidden="">
                                    {krajDomyslny == null ?
                                    t("podsumowanie.dane_osobowe.kraj_pochodzenia") + "*"
                                    :
                                    krajDomyslny.wartosc}
                            </option>
                            {slowniki &&
                                <>
                                    {
                                        krajePozostale
                                        .map(item => (<option value={item.nazwa}>{item.wartosc}</option>))
                                    }
                                </>
                            }
                        </select>
                    </div>
                </div>
            </div>
            <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.adres.tytul_zam")}</h1>
            <div className="newline"></div>
                {slowniki &&
                    <PodsumowanieAdres
                        slowniki = {slowniki}
                        typAdresu = {"zamieszkania"}
                        trybWalidacyjny = {trybWalidacyjny}
                    />
                }
            <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.adres.tytul_kor")}</h1>
            <div className="newline"></div>
            <div className="grid">
                <div className="row">
                    <div className="cell-6">
                        <label className="radio style2 required">
                            <input onClick={(event) => setKorespondencyjnyJakMiejsceZamieszkania(true)} type="radio" checked={korespondencyjnyJakMiejsceZamieszkania}/>
                            <div className="check"></div>
                            <div className="caption">{t("podsumowanie.adres.wybor.taki_sam")}</div>
                        </label>
                    </div>
                    <div className="cell-6">
                        <label className="radio style2 required">
                            <input onClick={(event) => setKorespondencyjnyJakMiejsceZamieszkania(false)} type="radio" checked={!korespondencyjnyJakMiejsceZamieszkania}/>
                            <div className="check"></div>
                            <div className="caption">{t("podsumowanie.adres.wybor.inny")}</div>
                            </label>
                    </div>
                </div>
            </div>
            {korespondencyjnyJakMiejsceZamieszkania === false &&
            <>
                {slowniki &&
                    <PodsumowanieAdres
                        slowniki = {slowniki}
                        typAdresu = {"korespondencyjny"}
                        trybWalidacyjny = {trybWalidacyjny}
                    />
                }
            </>
            }
            <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.dane_kontaktowe.tytul")}</h1>
            <div className="newline"></div>
            <div className="row">

                <div className="cell-md-5">
                    <div className="form-group">
                        <input type="text" placeholder={t("podsumowanie.dane_kontaktowe.email") + "*"} className="inputstyle"  onChange={(event) => setEmailKandydata({wartosc: event.target.value, min: 3})}/>
                        {czyBlednaWartosc(emailKandydata) &&
                            <span className="error_panel">
                                {t("podsumowanie.dane_kontaktowe.walidacja.email")}
                            </span>
                        }
                    </div>
                </div>
                <div className="cell-md-2">
                    <div className="form-groupx">
                        <select xdata-role="select" id= "numerKierunkowyCombobox" className="inputstyle" onChange={(event) => setNumerKierunkowyKandydata({wartosc: event.target.value, min: 1})}>
                            <option selected="selected" value={defaultowyNumerKierunkowy.kodKierunkowy}>{defaultowyNumerKierunkowy.wartosc}</option>
                            {slowniki &&
                                <>
                                    {
                                        slowniki.numeryKierunkowe
                                        .filter(item => item.domyslny == false)
                                        .map(item => (<option value={item.kodKierunkowy}>{item.wartosc}</option>))
                                    }
                                </>
                            }
                        </select>
                    </div>
                </div>
                <div className="cell-md-5">
                    <input type="text" name="phone" className="inputstyle" placeholder={t("podsumowanie.dane_kontaktowe.telefon") + "*"}  onChange={(event) => setTelefonKandydata({wartosc: event.target.value, min: 9})}/>
                    {czyBlednaWartosc(telefonKandydata) &&
                        <span className="error_panel">
                            {t("podsumowanie.dane_kontaktowe.walidacja.telefon")}
                        </span>
                    }
                </div>
            </div>


            {definicjeFormularzy != null &&
            <>
                {definicjeFormularzy
                .map(definicjaFormularza => (
                <>
                    {definicjaFormularza.pozycjeFormularza != null &&
                    <>
                        {definicjaFormularza.pozycjeFormularza
                        .map(formularz => (
                        <>
                            <h1>{definicjaFormularza.tytul}</h1>
                            <div className="newline"></div>
                            {czyFormularzTypuTakNie(formularz) == true ?
                            <>
                                <div className="newline newLineOption"></div>
                                <div className="grid">
                                    <div className="row">
                                        <div className="cell-4">
                                            <label className="radio style2">
                                                <input
                                                    type="radio"
                                                    id={"radioInput"+formularz.id}
                                                    name={"radioInput"+formularz.id}
                                                    onClick={() => oznaczOdpowiedzNaDefinicjachFormularzy(
                                                    {
                                                        odpowiedzNazwa: formularz.odpowiedzi[0].nazwa,
                                                        odpowiedzId: formularz.odpowiedzi[0].id,
                                                        formularzTyp: "TAKNIE",
                                                        formularzId: formularz.id

                                                    })}/>
                                                <div className="check"></div>
                                                <div className="caption">
                                                    {formularz.odpowiedzi[0].nazwa}
                                                </div>
                                            </label>
                                        </div>
                                        <div className="cell-4">
                                            <label className="radio style2">
                                                <input
                                                    type="radio"
                                                    id={"radioInput"+formularz.id}
                                                    name={"radioInput"+formularz.id}
                                                    onClick={() => oznaczOdpowiedzNaDefinicjachFormularzy(
                                                    {
                                                        odpowiedzNazwa: formularz.odpowiedzi[1].nazwa,
                                                        odpowiedzId: formularz.odpowiedzi[1].id,
                                                        formularzTyp: "TAKNIE",
                                                        formularzId: formularz.id})}/>
                                                <div className="check"></div>
                                                <div className="caption">
                                                    {formularz.odpowiedzi[1].nazwa}
                                                </div>
                                            </label>
                                        </div>
                                        {(definicjaFormularza.informacja) &&
                                        <div class="cell-md-4">
                                                <button
                                                    className="buttonyellow"
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => document.getElementById("formularzId"+formularz.id).style.display = "block"}>
                                                    {t("podsumowanie.przycisk.pokaz_szczegoly")}
                                                </button>
                                        </div>
                                        }
                                    </div>
                                    {(definicjaFormularza.informacja) &&
                                    <div className="row" id={"formularzId"+formularz.id} style={{display: "none"}}>
                                        <div className="cell-12">
                                            <div className="premiumrow">
                                                <br/>
                                                <img src={require("../images/prem.png")} style={{maxWidth: "30%",maxHeight: "30%",height: "auto"}}/>
                                                <br/>
                                                <div dangerouslySetInnerHTML={{__html: definicjaFormularza.informacja}} />
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {(trybWalidacyjny === true && formularz.odpowiedz == null) &&
                                    <div className="row">
                                        <div className="cell-12">
                                            <span className="error_panel">
                                               {t("podsumowanie.formularze.walidacja")}
                                            </span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                {czyFormularzJednaOdpowiedz(formularz) === true ?
                                    <div class="row">
                                        <div class="cell-md-12">
                                            <div class="form-group">
                                                <input
                                                type="text"
                                                placeholder={formularz.odpowiedzi[0].nazwa}
                                                onKeyPress={(event) => oznaczOdpowiedzNaDefinicjachFormularzy(
                                                    {
                                                    odpowiedzNazwa: event.target.value,
                                                    odpowiedzId: formularz.odpowiedzi[0].id,
                                                    formularzId: formularz.id,
                                                    formularzTyp: "TEXT",
                                                    formularz: formularz
                                                    })}
                                                className="inputstyle"/>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div class="row">
                                            <div class="cell-md-12">
                                                <select
                                                xxdata-role="select"
                                                class="inputstyle"
                                                  onChange={(event) => oznaczOdpowiedzNaDefinicjachFormularzy(
                                                  {
                                                  odpowiedzNazwa: event.target.textContent,
                                                  odpowiedzId: event.target.value,
                                                  formularzId: formularz.id,
                                                  formularzTyp: "MULTISELECT",
                                                  formularz: formularz
                                                  })}
                                                  >
                                                    <option value="" selected="" disabled="" hidden="">{t("podsumowanie.formularze.walidacja") + "*"}</option>
                                                    {formularz.odpowiedzi
                                                    .map(opcja => (
                                                    <option value={opcja.id}>{opcja.nazwa}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {(trybWalidacyjny === true && formularz.odpowiedz == null) &&
                                        <div className="row">
                                            <div className="cell-12">
                                                <span className="error_panel">
                                                    {t("podsumowanie.formularze.walidacja")}
                                                </span>
                                            </div>
                                        </div>
                                        }
                                    </>
                                }
                            </>
                            }
                        </>
                        ))}
                    </>
                    }
                </>
                ))}
            </>
           }
           <h1 style={{textTransform: "uppercase"}}>{t("podsumowanie.regulamin.tytul")}</h1>
           <div className="newline"></div>
           <span className="wyrazeniezgody">
                {t("podsumowanie.regulamin.wyrazenie_zgody")}
           </span>
           <label className="checkbox style2" >
                <input type="checkbox" onClick={(event) => oznaczOdpowiedzNaWszystkichWarunkachRekrutacyjnych({zgoda: event.target.checked})}/>
                <span class="check"></span>
                <div class="caption captioncombo">
                     {t("podsumowanie.regulamin.akceptuj_wszystko")}
                </div>
           </label>
            {(trybWalidacyjny === true && warunkiRekrutacyjne.filter(item => item.zgoda !== true).length !== 0) &&
            <div className="row">
                <div className="cell-12">
                    <span class="error_panel">
                        {t("podsumowanie.regulamin.walidacja.wszystkie")}
                    </span>
                </div>
            </div>
            }
           <button
               className="buttonyellow"
               style={{cursor: "pointer", width: "100%"}}
               onClick={() => setZgodyPokarzSzczegoly(!zgodyPokarzSzczegoly)}>
               {zgodyPokarzSzczegoly === false ? t("podsumowanie.przycisk.pokaz_szczegoly") : t("podsumowanie.przycisk.ukryj_szczegoly")}
           </button>
           {zgodyPokarzSzczegoly &&
           <div id="allreg" style={{marginTop: "30px"}}>
                {warunkiRekrutacyjne
                .map(warunekRekrutacyjny => (
                <>
                    <div class="row rowcheck">
                        <div class="cell-md-8">
                            <label class="checkbox style2">
                                <input
                                    type="checkbox"
                                    class="checkboxreg"
                                    checked={warunekRekrutacyjny.zgoda === true}
                                    onClick={(event) => oznaczOdpowiedzNaWarunkachRekrutacyjnych({zgoda: event.target.checked, warunekRekrutacyjnyId: warunekRekrutacyjny.id})}/>
                                <span class="check"></span>
                                <div class="caption captioncombo">
                                {warunekRekrutacyjny.tytul}

                                </div>
                            </label>
                        </div>
                        <div class="cell-md-4">

                            <button
                                className="buttonyellow"
                                style={{cursor: "pointer", width: "100%"}}
                                onClick={() => document.getElementById("trescWarunkow"+warunekRekrutacyjny.id).style.display = "block"}>
                                {warunekRekrutacyjny.trescWarunkow.startsWith("<a") ?
                                <div dangerouslySetInnerHTML={{__html: warunekRekrutacyjny.trescWarunkow}} />
                                :
                                t("podsumowanie.przycisk.pokaz")
                                }
                            </button>
                        </div>
                        <div id={"trescWarunkow"+ warunekRekrutacyjny.id} style={{display: "none", color: "black", fontSize: "10px", marginLeft: "10px"}}>
                            {warunekRekrutacyjny.trescWarunkow}
                        </div>
                    </div>
                    {(trybWalidacyjny === true && warunekRekrutacyjny.zgoda !== true) &&
                        <span class="error_panel">
                        {t("podsumowanie.regulamin.walidacja.pojedynczy")}
                        </span>
                    }
                </>
                ))}
           </div>
           }
           <div style={{textAlign: "center", width: "100%"}}>
               <br/>
               <button
                    id="finalButton"
                    class="buttongreen"
                    name="Next"
                    onClick={ () => {
                            zbierzDaneDokonajWalidacjiWyslijRestem();
                        }}
                    style={{width: "100%"}}>
                    {t("podsumowanie.przycisk.zakoncz")}
               </button>
               <br/>
           </div>
         </div>
         </>
        )
}
export default Podsumowanie;
