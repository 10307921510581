export const  wyciagnijPierwszyElement = (elementy) => {

    if (elementy == null ) return null;

    if(elementy.length > 0){
    return elementy[0];
    }else{
        return null;
    }
}

export const  wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym = (elementy) => {

    if (elementy == null ) return null;

    if(elementy.length > 1){

       return (elementy)
        .filter((item, index) => elementy.indexOf(item) === index)
        .filter( item => item != wyciagnijPierwszyElement(elementy))

    }else{
        return null;
    }
}
