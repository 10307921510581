import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';

import { wyznaczElementDomyslny, wyznaczElementyPozostale } from "../../utils/PodsumowanieFunkcje"
//import rest from '../../utils/RestClient'
import { czyBlednaWartosc } from '../../utils/PodsumowanieFunkcje'
import PodsumowanieAdresKrajowy from './PodsumowanieAdresKrajowy'
import PodsumowanieAdresZagraniczny from './PodsumowanieAdresZagraniczny'

const PodsumowanieAdres = ({slowniki, typAdresu, trybWalidacyjny}) => {
    let { i18n, t } = useTranslation();
    let lang = i18n.language;
    const [kraj, setKraj] = useState(null);

    let krajDomyslny = wyznaczElementDomyslny(slowniki?.kraje)
    let krajePozostale = wyznaczElementyPozostale(slowniki?.kraje)

    if(kraj == null) setKraj(krajDomyslny.nazwa)

    return (
            <>
                <div className="row">
                    <div className="cell-md-12">
                       <div className="xform-group">
                           <select
                           id = {typAdresu + "_kraj"}
                           xdata-role="select"
                           className="inputstyle"
                           onChange={(event) =>  setKraj(event.target.value)}>
                                <option
                                    selected=
                                        {krajDomyslny == null ?
                                        null
                                        :
                                        krajDomyslny.nazwa}
                                    disabled=""
                                    hidden="">
                                        {krajDomyslny == null ?
                                        t("podsumowanie.adres.kraj") + "*"
                                        :
                                        krajDomyslny.wartosc}
                                </option>
                                {slowniki &&
                                    <>
                                        {
                                            krajePozostale
                                            .map(item => (<option value={item.nazwa}>{item.wartosc}</option>))
                                        }
                                    </>
                                }
                           </select>
                           {(kraj == null && trybWalidacyjny == true) &&
                                <span className="error_panel">
                                    {t("podsumowanie.adres.walidacja.kraj")}
                                </span>
                           }
                       </div>
                   </div>
                </div>

               {kraj == "Rzeczpospolita Polska" ?
                    <PodsumowanieAdresKrajowy
                        slowniki = {slowniki}
                        typAdresu = {typAdresu}
                        trybWalidacyjny = {trybWalidacyjny}
                    />
               :
                    <PodsumowanieAdresZagraniczny
                        slowniki = {slowniki}
                        typAdresu = {typAdresu}
                        trybWalidacyjny = {trybWalidacyjny}
                    />
               }
            </>
        )
}
export default PodsumowanieAdres;
