import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';

import { czyBlednaWartosc } from '../../utils/PodsumowanieFunkcje'

const PodsumowanieAdresZagraniczny = ({slowniki, typAdresu, trybWalidacyjny}) => {
    let { i18n, t } = useTranslation();
    let lang = i18n.language;

    const [ulicaKandydata, setUlicaKandydata] = useState({wartosc: ""});
    const [kodPocztowyKandydata, setKodPocztowyKandydata] = useState({wartosc: ""});
    const [miastoKandydata, setMiastoKandydata] = useState({wartosc: ""});
    const [rodzajMiejscowosciKandydata, setRodzajMiejscowosciKandydata] = useState({wartosc: ""});
    const [wojewodztwoKandydata, setWojewodztwoKandydata] = useState({wartosc: ""});
    const [numerDomuKandydata, setNumerDomuKandydata] = useState({wartosc: ""});
    return (
            <>
                <div className="row">
                    <div className="cell-md-6">
                        <div className="form-group">
                            <input type="text"
                                onChange={(event) => setUlicaKandydata({wartosc: event.target.value, min: 3})}
                                placeholder={t("podsumowanie.adres.ulica") + "*"}
                                className="inputstyle"
                                id = {typAdresu+"_ulica"}
                             />
                            {(ulicaKandydata.wartosc.length < 3 && trybWalidacyjny == true) &&
                            <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.ulica")}
                            </span>
                            }
                        </div>
                    </div>
                    <div className="cell-md-6">
                        <div className="form-group">
                            <input type="text"
                                onChange={(event) => setNumerDomuKandydata({wartosc: event.target.value, min: 1})}
                                placeholder={t("podsumowanie.adres.numer_domu") + "*"}
                                className="inputstyle"
                                id = {typAdresu+"_numer_domu"}
                             />
                             {(numerDomuKandydata.wartosc.length < 1 && trybWalidacyjny == true) &&
                            <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.numer_domu")}
                            </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell-md-4">
                        <div className="form-group">
                            <input
                                onChange={(event) => setKodPocztowyKandydata({wartosc: event.target.value, min: 3})}
                                id = {typAdresu+"_kod_pocztowy"}
                                type="text"
                                placeholder={t("podsumowanie.adres.kod_pocztowy") + "*"}
                                className="inputstyle" />
                            {(kodPocztowyKandydata.wartosc.length < 3 && trybWalidacyjny == true) &&
                                <span className="error_panel">
                                    {t("podsumowanie.adres.walidacja.kod_pocztowy")}
                                </span>
                            }
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="form-group">
                            <input
                                onChange={(event) => setMiastoKandydata({wartosc: event.target.value, min: 3})}
                                id = {typAdresu+"_miasto"}
                                type="text"
                                placeholder={t("podsumowanie.adres.miasto") + "*"}
                                className="inputstyle" />
                            {(miastoKandydata.wartosc.length < 3 && trybWalidacyjny == true) &&
                            <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.miasto")}
                            </span>
                            }
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="form-groupx">
                            <select
                            onChange={(event) => setRodzajMiejscowosciKandydata({wartosc: event.target.value, min: 1})}
                            xdata-role="select"
                            className="inputstyle"
                            id = {typAdresu+"_rodzaj_miejscowosci"}>
                                <option selected="" disabled="" hidden="" value="">{t("podsumowanie.adres.rodzaj_miejscowosci.tytul") + "*"}</option>
                                <option value="1">{t("podsumowanie.adres.rodzaj_miejscowosci.miasto")}</option>
                                <option value="2">{t("podsumowanie.adres.rodzaj_miejscowosci.wies")}</option>
                            </select>
                            {(rodzajMiejscowosciKandydata.wartosc.length < 1 && trybWalidacyjny == true) &&
                            <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.rodzaj_miejscowosci")}
                            </span>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
}
export default PodsumowanieAdresZagraniczny;
