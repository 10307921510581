import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/metro-all.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios'
import Error from './Error'
import rest from './utils/RestClient'

// Bootstrap CSS
import 'react-bootstrap/dist/react-bootstrap'
// Bootstrap Bundle JS
import 'react-bootstrap/dist/react-bootstrap.min'

const root = ReactDOM.createRoot(document.getElementById('root'));
let path = (window.location.pathname.split("/").pop());

let dks = "dks";
if(path != null && path.length > 0){
dks = dks +"/" +path;
}

axios({
  method: 'get',
    url: rest+dks,
})
  .then(response => {
root.render(
  <React.StrictMode>
    <App
        dksy={response.data}
        promoLink={path}
    />
  </React.StrictMode>
);
  })
  .catch(error => {
    <Error/>
  });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
