import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "pl",
    fallbackLng: "pl",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        pl: {
            translation: {
                brak_naborow: "Brak naborów",
                layout: {
                    tytul: "Rekrutacja elektroniczna",
                    wybrany: {
                        kierunek: "Wybrany kierunek",
                        specjalnosc: "Wybrana specjalność",
                        poziom: "Wybrany poziom",
                        tryb: "Wybrany tryb",
                        miasto: "Wybrane miasto"
                    },
                    pomoc: "Potrzebujesz pomocy?",
                    zadzwon: "Zadzwoń do nas",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Wybór studiów",
                        studia_lic_mgr: "Studia licencjackie i magisterskie",
                        studia_podyplomowe: "Studia podyplomowe MBA, DBA, LL.M, MSc, ze specjalnościami",
                        studia_podyplomowe_inne: "Inne studia podyplomowe",
                        przycisk: {
                            wybierz_miasto: "Wybierz miasto",
                            wybierz_specjalnosc: "Wybierz specjalność"
                        }
                    },
                    specjalnosci: {
                        tytul: "Wybór kierunku",
                        wyszukiwarka: "Wyszukaj specjalność",
                        specjalnosc: "Wybierz specjalność, którą chcesz studiować",
                        przycisk: {
                            powrot: "Powrót",
                            wybierz_miasto: "Wybierz miasto"
                        }
                    },
                    miasta: {
                        tytul: "Wybór miejsca studiów",
                        miasto: "Wybierz miasto, w którym chcesz studiować",
                        przycisk: {
                            powrot: "Powrót",
                            wybierz_poziom_studiow: "Wybierz poziom studiów"
                        }
                    },
                    poziomu: {
                        tytul: "Wybór poziomu studiów",
                        poziom: "Wybierz poziom studiów, na którym chcesz studiować",
                        przycisk: {
                            powrot: "Powrót",
                            wybierz_tryb_studiow: "Wybierz tryb studiów"
                        }
                    },
                    trybu: {
                        tytul: "Wybór trybu",
                        tryb: "Wybierz tryb, na którym chcesz studiować",
                        przycisk: {
                            powrot: "Powrót",
                            podsumowanie: "Podsumowanie"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Dane do umowy",
                    wybrane_studia: {
                        tytul: "Wybrane studia",
                        poziom: "Poziom studiów",
                        tryb: "Tryb",
                        kierunek: "Kierunek",
                        specjalnosc: "Specjalność",
                        miasto: "Miasto",
                    },
                    obywatelstwo: {
                        tytul: "Obywatelstwo",
                        polskie: "Polskie",
                        obcokrajowiec: "Obcokrajowiec"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "Czy posiadasz numer PESEL ?",
                            tak: "Tak",
                            nie: "Nie"
                        },
                        rodzaj_dowodu: {
                            tytul : "rodzaj dowodu osobistego",
                            dowod_osobisty: "dowód osobisty",
                            paszport: "paszport",
                            karta_pobytu: "karta pobytu",
                            polski_dokument_podrozy: "polski dokument podruzy dla cudzoziemca",
                            tymczasowy_polski_dokument_podrozy: "tymczasowy polski dokument podruzy dla cudzoziemca",
                            polski_dokument_tozsamosci_cudzoziemca: "polski dokument tozsamosci cudzoziemca"
                        },
                        seria_numer_dowodu : "seria i numer",
                        walidacja: {
                            rodzaj_dowodu: "Podaj rodzaj dowodu",
                            seria_numer_dowodu: "Podaj serie i numer dowodu"
                        }
                    },
                    m_legitymacja: {
                        tytul: "Legitymacja",
                        wydanie: "Proszę o wydanie legitymacji studenckiej (ELS) (koszt: 22 zł)."
                    },
                    dane_osobowe: {
                        tytul: "dane osobowe",
                        imie: "imie",
                        imie_drugie: "drugie imie",
                        nazwisko: "nazwisko",
                        pesel: "Numer PESEL",
                        data_ur: "data urodzenia",
                        miejsce_ur: "miejsce urodzenia",
                        plec: {
                            tytul: "płeć",
                            kobieta: "Kobieta",
                            mezczyzna: "Mężczyzna"
                        },
                        obywatelstwo: "obywatelstwo",
                        kraj_pochodzenia: "kraj pochodzenia",
                        walidacja: {
                            imie: "Podaj swoje imię",
                            nazwisko: "Podaj swoje nazwisko",
                            pesel: "Podaj swój numer PESEL",
                            pesel_bledny: "Błędny numer PESEL",
                            data_ur: "Podaj datę urodzenia",
                            miejsce_ur: "Podaj miejsce urodzenia",
                            plec: "Podaj płeć",
                            obywatelstwo: "Podaj swoje obywatelstwo",
                            kraj_pochodzenia: "Podaj kraj pochodzenia"
                        }
                    },
                    adres: {
                        tytul_zam: "Adres zamieszkania",
                        tytul_kor: "Adres korespondencyjny",
                        wybor: {
                            taki_sam: "Taki sam jak adres zamieszkania",
                            inny: "Inny"
                        },
                        ulica: "ulica",
                        numer_domu: "numer domu / numer mieszkania",
                        kod_pocztowy: "kod pocztowy",
                        miasto: "miasto",
                        rodzaj_miejscowosci: {
                            tytul: "rodzaj miejscowości",
                            miasto: "miasto",
                            wies: "wies"
                        },
                        kraj: "kraj",
                        wojewodztwo: "województwo",
                        walidacja: {
                            ulica: "Podaj swój adres",
                            numer_domu: "Podaj numer domu / mieszkania",
                            kod_pocztowy: "Podaj swój kod pocztowy",
                            miasto: "Podaj swoje miasto",
                            rodzaj_miejscowosci: "Podaj rodzaj miejscowości",
                            wojewodztwo: "Podaj swoje województwo",
                            kraj: "Podaj swój kraj"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "dane kontaktowe",
                        email: "adres e-mail",
                        telefon: "telefon komórkowy",
                        walidacja: {
                            email: "Podaj swój adres e-mail",
                            telefon: "Podaj swój numer komórkowy"
                        }
                    },
                    formularze: {
                        walidacja: "Wybierz jedną z opcji"
                    },
                    regulamin:{
                        tytul: "Akceptacja regulaminu studiów",
                        akceptuj_wszystko: "Akceptuj wszystko",
                        wyrazenie_zgody: "Wyrażenie zgody na warunki regulaminu studiów Uczelni Biznesu i Nauk Stosowanych ''Varsovia'' i zasad przetwarzania i wykorzystywania danych osobowych.",
                        walidacja: {
                            wszystkie: "Aby zakończyć rekrutację musisz zaakceptować wszystkie zgody.",
                            pojedynczy: "Aby zakończyć rekrutację musisz zaakceptować zgodę"
                        }
                    },
                    przycisk: {
                        pokaz: "pokaż",
                        pokaz_szczegoly: "Pokaż szczegóły",
                        ukryj_szczegoly: "Ukryj szczegóły",
                        wstecz: "wstecz",
                        zakoncz: "Zapisz i zakończ rekrutację"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Kandydat został zapisany wcześniej na wybraną ofertę"
                    }
                }
            },
        },
        eng: {
            translation: {
                brak_naborow: "No studies",
                layout: {
                    tytul: "Electronic recruitment",
                    wybrany: {
                        kierunek: "Selected direction",
                        specjalnosc: "Selected specialty",
                        poziom: "Selected level",
                        tryb: "Selected mode",
                        miasto: "Selected city"
                    },
                    pomoc: "Need help?",
                    zadzwon: "Call us",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Choice of studies",
                        studia_lic_mgr: "Bachelor's and Master's Degree studies",
                        studia_podyplomowe: "Postgraduate studies MBA, DBA, LL.M, MSc, with specialties",
                        studia_podyplomowe_inne: "Other postgraduate studies",
                        przycisk: {
                            wybierz_miasto: "Select the city",
                            wybierz_specjalnosc: "Choose your specialization"
                        }
                    },
                    specjalnosci: {
                        tytul: "Specialty selection",
                        wyszukiwarka: "Find specialization",
                        specjalnosc: "Choose your specialization",
                        przycisk: {
                            powrot: "Back",
                            wybierz_miasto: "Select the city"
                        }
                    },
                    miasta: {
                        tytul: "Choice of a place of study",
                        miasto: "Choose the city you want to study in",
                        przycisk: {
                            powrot: "Back",
                            wybierz_poziom_studiow: "Select the level of study"
                        }
                    },
                    poziomu: {
                        tytul: "Select the level of study",
                        poziom: "Select the level of study at which you want to study",
                        przycisk: {
                            powrot: "Back",
                            wybierz_tryb_studiow: "Select the mode of study"
                        }
                    },
                    trybu: {
                        tytul: "Mode selection",
                        tryb: "To proceed, you must select the mode of study",
                        przycisk: {
                            powrot: "Back",
                            podsumowanie: "Next"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Information for the contract",
                    wybrane_studia: {
                        tytul: "Selected study programmes",
                        poziom: "Level of study",
                        tryb: "Mode",
                        kierunek: "Field of study",
                        specjalnosc: "Major",
                        miasto: "City",
                    },
                    obywatelstwo: {
                        tytul: "Citizenship",
                        polskie: "Polish",
                        obcokrajowiec: "Foreigner"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "Do you have a PESEL number ?",
                            tak: "Yes",
                            nie: "No"
                        },
                        rodzaj_dowodu: {
                            tytul : "type of identity card",
                            dowod_osobisty: "personal ID",
                            paszport: "passport",
                            karta_pobytu: "residence card",
                            polski_dokument_podrozy: "polish travel document for foreigners",
                            tymczasowy_polski_dokument_podrozy: "temporary polish travel document for foreigner",
                            polski_dokument_tozsamosci_cudzoziemca: "Polish identity document of a foreigner"
                        },
                        seria_numer_dowodu : "series and number",
                        walidacja: {
                            rodzaj_dowodu: "Specify the type of evidence",
                            seria_numer_dowodu: "Enter the series and number of the proof"
                        }
                    },
                    m_legitymacja: {
                        tytul: "electronic student ID (mLegitymacja)",
                        wydanie: "I want an electronic student ID card (ELS)."
                    },
                    dane_osobowe: {
                        tytul: "personal data",
                        imie: "first name",
                        imie_drugie: "middle name",
                        nazwisko: "surname",
                        pesel: "PESEL number",
                        data_ur: "date of birth",
                        miejsce_ur: "place of birth",
                        plec: {
                            tytul: "sex",
                            kobieta: "female",
                            mezczyzna: "male"
                        },
                        obywatelstwo: "citizenship",
                        kraj_pochodzenia: "country of origin",
                        walidacja: {
                            imie: "Your first name",
                            nazwisko: "Your surname ",
                            pesel: "Your PESEL number",
                            pesel_bledny: "Wrong PESEL number",
                            data_ur: "Your date of birth",
                            miejsce_ur: "Your place of birth",
                            plec: "Your sex",
                            obywatelstwo: "Your citizenship",
                            kraj_pochodzenia: "Your country of origin"
                        }
                    },
                    adres: {
                        tytul_zam: "Address of residence",
                        tytul_kor: "Address of correspondence",
                        wybor: {
                            taki_sam: "The same as the address of residence",
                            inny: "Other"
                        },
                        ulica: "street",
                        numer_domu: "house number / apartment number",
                        kod_pocztowy: "postal code",
                        miasto: "city",
                        rodzaj_miejscowosci: {
                            tytul: "type of town",
                            miasto: "city",
                            wies: "village"
                        },
                        kraj: "country",
                        wojewodztwo: "voivodeship",
                        walidacja: {
                            ulica: "Your address",
                            kod_pocztowy: "Your postal code",
                            miasto: "Your town/city",
                            rodzaj_miejscowosci: "Enter the type of town",
                            wojewodztwo: "Your voivodeship",
                            kraj: "Your country"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "contact details",
                        email: "e-mail address",
                        telefon: "mobile phone number",
                        walidacja: {
                            email: "Enter your e-mail address",
                            telefon: "Enter your mobile phone number"
                        }
                    },
                    formularze: {
                        walidacja: "Choose one of the options"
                    },
                    regulamin:{
                        tytul: "Acceptance of study regulations",
                        akceptuj_wszystko: "Accept everything",
                        wyrazenie_zgody: "Consent to the terms of the 'Varsovia' University of Business and Applied Sciences study regulations and the rules for the processing and use of personal data.",
                        walidacja: {
                            wszystkie: "To go to the next part, you need to correctly fill in the whole form.",
                            pojedynczy: "To go to the next part, you must accept the consent"
                        }
                    },
                    przycisk: {
                        pokaz: "show",
                        pokaz_szczegoly: "show details",
                        ukryj_szczegoly: "hide details",
                        wstecz: "back",
                        zakoncz: "Save and complete recruitment"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "The candidate has been previously registered for the selected offer"
                    }
                }
            },
        },
        ru: {
            translation: {
                brak_naborow: "Нет исследований",
                layout: {
                    tytul: "ЭЛЕКТРОННЫЙ НАБОР",
                    wybrany: {
                        kierunek: "Выбранное направление",
                        specjalnosc: "Выбранная специальность",
                        poziom: "Выбранный уровень",
                        tryb: "Выбранный режим",
                        miasto: "Выбранный город"
                    },
                    pomoc: "Нужна помощь?",
                    zadzwon: "Позвоните нам",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Направления обучения",
                        studia_lic_mgr: "ЛИЦЕНЗИЯ И МАГИСТРАТУРА",
                        studia_podyplomowe: "Аспирантура MBA, DBA, LL.M, MSc по специальностям",
                        studia_podyplomowe_inne: "Другое последипломное образование",
                        przycisk: {
                            wybierz_miasto: "Выберите город",
                            wybierz_specjalnosc: "Выберите свою специализацию"
                        }
                    },
                    specjalnosci: {
                        tytul: "Выбор специальности",
                        wyszukiwarka: "Найти специализацию",
                        specjalnosc: "Выберите свою специализацию",
                        przycisk: {
                            powrot: "Назад",
                            wybierz_miasto: "Выберите город"
                        }
                    },
                    miasta: {
                        tytul: "Выбор места учебы",
                        miasto: "Выберите город, в котором хотите учиться",
                        przycisk: {
                            powrot: "Назад",
                            wybierz_poziom_studiow: "Выберите уровень обучения"
                        }
                    },
                    poziomu: {
                        tytul: "Выберите уровень обучения",
                        poziom: "Выберите уровень обучения, на котором вы хотите учиться",
                        przycisk: {
                            powrot: "Назад",
                            wybierz_tryb_studiow: "Выберите форму обучения"
                        }
                    },
                    trybu: {
                        tytul: "Выбор режима",
                        tryb: "Для продолжения необходимо выбрать форму обучения",
                        przycisk: {
                            powrot: "Назад",
                            podsumowanie: "Следующий"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Информация для контракта",
                    wybrane_studia: {
                        tytul: "Избранные учебные программы",
                        poziom: "Уровень изучения",
                        tryb: "Режим",
                        kierunek: "Область исследования",
                        specjalnosc: "Главный",
                        miasto: "Город",
                    },
                    obywatelstwo: {
                        tytul: "Гражданство",
                        polskie: "Польский",
                        obcokrajowiec: "Иностранец"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "У вас есть номер PESEL?",
                            tak: "Да",
                            nie: "Не"
                        },
                        rodzaj_dowodu: {
                            tytul : "тип удостоверения личности",
                            dowod_osobisty: "удостоверение личности",
                            paszport: "паспорт",
                            karta_pobytu: "карточка резидента",
                            polski_dokument_podrozy: "Польский проездной документ для иностранцев",
                            tymczasowy_polski_dokument_podrozy: "временный польский проездной документ для иностранца",
                            polski_dokument_tozsamosci_cudzoziemca: "Польский документ, удостоверяющий личность иностранца"
                        },
                        seria_numer_dowodu : "серия и номер",
                        walidacja: {
                            rodzaj_dowodu: "Укажите тип доказательства",
                            seria_numer_dowodu: "Укажите серию и номер пробного экземпляра"
                        }
                    },
                    m_legitymacja: {
                        tytul: "электронный студенческий билет (mLegitymacja)",
                        wydanie: "Мне нужен электронный студенческий билет (ELS)."
                    },
                    dane_osobowe: {
                        tytul: "личные данные",
                        imie: "имя",
                        imie_drugie: "второе имя",
                        nazwisko: "фамилия",
                        pesel: "номер PESEL",
                        data_ur: "Дата рождения",
                        miejsce_ur: "место рождения",
                        plec: {
                            tytul: "секс",
                            kobieta: "женский",
                            mezczyzna: "мужской"
                        },
                        obywatelstwo: "гражданство",
                        kraj_pochodzenia: "страна происхождения",
                        walidacja: {
                            imie: "Твое имя",
                            nazwisko: "Ваша фамилия ",
                            pesel: "Ваш номер PESEL",
                            pesel_bledny: "Неправильный номер PESEL",
                            data_ur: "Ваша дата рождения",
                            miejsce_ur: "Ваше место рождения",
                            plec: "Ваш пол",
                            obywatelstwo: "Ваше гражданство",
                            kraj_pochodzenia: "Ваша страна происхождения"
                        }
                    },
                    adres: {
                        tytul_zam: "Адрес проживания",
                        tytul_kor: "Адрес для переписки",
                        wybor: {
                            taki_sam: "То же, что и адрес проживания",
                            inny: "Другой"
                        },
                        ulica: "улица",
                        kod_pocztowy: "Почтовый индекс",
                        miasto: "город",
                        rodzaj_miejscowosci: {
                            tytul: "тип города",
                            miasto: "город",
                            wies: "деревня"
                        },
                        kraj: "страна",
                        wojewodztwo: "воеводство",
                        walidacja: {
                            ulica: "Ваш адрес",
                            kod_pocztowy: "Ваш почтовый индекс",
                            miasto: "Ваш город/город",
                            rodzaj_miejscowosci: "Введите тип города",
                            wojewodztwo: "Ваше воеводство",
                            kraj: "Твоя страна"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "Контактная информация",
                        email: "Адрес электронной почты",
                        telefon: "номер мобильного телефона",
                        walidacja: {
                            email: "Введите ваш адрес электронной почты",
                            telefon: "Введите номер вашего мобильного телефона"
                        }
                    },
                    formularze: {
                        walidacja: "Выберите один из вариантов"
                    },
                    regulamin:{
                        tytul: "Принятие правил обучения",
                        akceptuj_wszystko: "Примите все",
                        wyrazenie_zgody: "Согласие с условиями обучения Collegium Humanum и правилами обработки и использования персональных данных.",
                        walidacja: {
                            wszystkie: "Чтобы перейти к следующей части, вам необходимо правильно заполнить всю форму.",
                            pojedynczy: "Для перехода к следующей части необходимо принять согласие."
                        }
                    },
                    przycisk: {
                        pokaz: "показывать",
                        pokaz_szczegoly: "показать детали",
                        ukryj_szczegoly: "скрыть детали",
                        wstecz: "назад",
                        zakoncz: "Сохранить и завершить набор персонала"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Кандидат ранее был зарегистрирован на выбранное предложение"
                    }
                }
            },
        },
        sk: {
            translation: {
                brak_naborow: "Žiadne štúdie",
                layout: {
                    tytul: "Elektronický nábor",
                    wybrany: {
                        kierunek: "Zvolený smer",
                        specjalnosc: "Vybraná špecialita",
                        poziom: "Vybraná úroveň",
                        tryb: "Zvolený režim",
                        miasto: "Vybrané mesto"
                    },
                    pomoc: "Potrebujete pomoc?",
                    zadzwon: "Zavolajte nám",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Výber štúdia",
                        studia_lic_mgr: "Bakalárske a magisterské štúdium",
                        studia_podyplomowe: "Postgraduálne štúdium MBA, DBA, LL.M, MSc so špecializáciou",
                        studia_podyplomowe_inne: "Ďalšie postgraduálne štúdium",
                        przycisk: {
                            wybierz_miasto: "Vyberte mesto",
                            wybierz_specjalnosc: "Vyberte si svoju špecializáciu"
                        }
                    },
                    specjalnosci: {
                        tytul: "Výber špeciality",
                        wyszukiwarka: "Nájdite špecializáciu",
                        specjalnosc: "Vyberte si svoju špecializáciu",
                        przycisk: {
                            powrot: "späť",
                            wybierz_miasto: "Vyberte mesto"
                        }
                    },
                    miasta: {
                        tytul: "Výber miesta štúdia",
                        miasto: "Vyberte si mesto, v ktorom chcete študovať",
                        przycisk: {
                            powrot: "späť",
                            wybierz_poziom_studiow: "Vyberte úroveň štúdia"
                        }
                    },
                    poziomu: {
                        tytul: "Vyberte úroveň štúdia",
                        poziom: "Vyberte úroveň štúdia, na ktorej chcete študovať",
                        przycisk: {
                            powrot: "späť",
                            wybierz_tryb_studiow: "Vyberte spôsob štúdia"
                        }
                    },
                    trybu: {
                        tytul: "Výber režimu",
                        tryb: "Ak chcete pokračovať, musíte vybrať spôsob štúdia",
                        przycisk: {
                            powrot: "späť",
                            podsumowanie: "Ďalšie"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Informácie k zmluve",
                    wybrane_studia: {
                        tytul: "Vybrané študijné programy",
                        poziom: "Úroveň štúdia",
                        tryb: "Režim",
                        kierunek: "Odbor",
                        specjalnosc: "Major",
                        miasto: "Mesto",
                    },
                    obywatelstwo: {
                        tytul: "Občianstvo",
                        polskie: "poľský",
                        obcokrajowiec: "Cudzinec"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "Máte číslo PESEL?",
                            tak: "Áno",
                            nie: "Nie"
                        },
                        rodzaj_dowodu: {
                            tytul : "typ preukazu totožnosti",
                            dowod_osobisty: "preukaz totožnosti",
                            paszport: "pas",
                            karta_pobytu: "pobytový preukaz",
                            polski_dokument_podrozy: "poľský cestovný doklad pre cudzincov",
                            tymczasowy_polski_dokument_podrozy: "dočasný poľský cestovný doklad pre cudzinca",
                            polski_dokument_tozsamosci_cudzoziemca: "Poľský doklad totožnosti cudzinca"
                        },
                        seria_numer_dowodu : "séria a číslo",
                        walidacja: {
                            rodzaj_dowodu: "Uveďte typ dôkazu",
                            seria_numer_dowodu: "Uveďte sériu a číslo dôkazu"
                        }
                    },
                    m_legitymacja: {
                        tytul: "elektronický študentský preukaz (mLegitymacja)",
                        wydanie: "Chcem elektronický preukaz študenta (ELS)."
                    },
                    dane_osobowe: {
                        tytul: "osobné údaje",
                        imie: "krstné meno",
                        imie_drugie: "stredné meno",
                        nazwisko: "priezvisko",
                        pesel: "číslo PESEL",
                        data_ur: "dátum narodenia",
                        miejsce_ur: "miesto narodenia",
                        plec: {
                            tytul: "sex",
                            kobieta: "Žena",
                            mezczyzna: "Muž"
                        },
                        obywatelstwo: "občianstvo",
                        kraj_pochodzenia: "krajina pôvodu",
                        walidacja: {
                            imie: "Tvoje krstné meno",
                            nazwisko: "Vaše priezvisko ",
                            pesel: "Vaše číslo PESEL",
                            pesel_bledny: "Nesprávne číslo PESEL",
                            data_ur: "Tvoj dátum narodenia",
                            miejsce_ur: "Miesto vášho narodenia",
                            plec: "Tvoj sex",
                            obywatelstwo: "Vaše občianstvo",
                            kraj_pochodzenia: "Vaša krajina pôvodu"
                        }
                    },
                    adres: {
                        tytul_zam: "Adresa bydliska",
                        tytul_kor: "Korešpondenčná adresa",
                        wybor: {
                            taki_sam: "Rovnako ako adresa bydliska",
                            inny: "Iné"
                        },
                        ulica: "ulica",
                        kod_pocztowy: "poštové smerovacie číslo",
                        miasto: "mesto",
                        rodzaj_miejscowosci: {
                            tytul: "typ mesta",
                            miasto: "mesto",
                            wies: "dedina"
                        },
                        kraj: "krajina",
                        wojewodztwo: "vojvodstvo",
                        walidacja: {
                            ulica: "Vaša adresa",
                            kod_pocztowy: "Vaše PSČ",
                            miasto: "Vaše mesto/mesto",
                            rodzaj_miejscowosci: "Zadajte typ mesta",
                            wojewodztwo: "Vaše vojvodstvo",
                            kraj: "Tvoja krajina"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "kontaktné údaje",
                        email: "emailová adresa",
                        telefon: "Číslo mobilného telefónu",
                        walidacja: {
                            email: "Vložte svoju e-mailovú adresu",
                            telefon: "Zadajte číslo svojho mobilného telefónu"
                        }
                    },
                    formularze: {
                        walidacja: "Vyberte si jednu z možností"
                    },
                    regulamin:{
                        tytul: "Akceptácia študijného poriadku",
                        akceptuj_wszystko: "Prijmite všetko",
                        wyrazenie_zgody: "Súhlas s podmienkami študijného poriadku Collegium Humanum a pravidlami spracovania a používania osobných údajov.",
                        walidacja: {
                            wszystkie: "Ak chcete prejsť na ďalšiu časť, musíte správne vyplniť celý formulár.",
                            pojedynczy: "Ak chcete prejsť na ďalšiu časť, musíte súhlasiť."
                        }
                    },
                    przycisk: {
                        pokaz: "šou",
                        pokaz_szczegoly: "zobraziť podrobnosti",
                        ukryj_szczegoly: "skryť detaily",
                        wstecz: "späť",
                        zakoncz: "Uložte a dokončite nábor"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Kandidát bol už predtým zaregistrovaný na vybranú ponuku"
                    }
                }
            },
        },
		cs: {
            translation: {
                brak_naborow: "Žádné studie",
                layout: {
                    tytul: "Elektronický nábor",
                    wybrany: {
                        kierunek: "Zvolený směr",
                        specjalnosc: "Vybraná specialita",
                        poziom: "Vybraná úroveň",
                        tryb: "Vybraný režim",
                        miasto: "Vybrané město"
                    },
                    pomoc: "Potřebovat pomoc?",
                    zadzwon: "Zavolejte nám",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Výběr studia",
                        studia_lic_mgr: "Bakalářské a magisterské studium",
                        studia_podyplomowe: "Postgraduální studium MBA, DBA, LL.M, MSc, se specialitami",
                        studia_podyplomowe_inne: "Další postgraduální studium",
                        przycisk: {
                            wybierz_miasto: "Vyberte město",
                            wybierz_specjalnosc: "Vyberte si svou specializaci"
                        }
                    },
                    specjalnosci: {
                        tytul: "Výběr speciality",
                        wyszukiwarka: "Najděte specializaci",
                        specjalnosc: "Vyberte si svou specializaci",
                        przycisk: {
                            powrot: "Zadní",
                            wybierz_miasto: "Vyberte město"
                        }
                    },
                    miasta: {
                        tytul: "Výběr místa studia",
                        miasto: "Vyberte si město, ve kterém chcete studovat",
                        przycisk: {
                            powrot: "Zadní",
                            wybierz_poziom_studiow: "Vyberte úroveň studia"
                        }
                    },
                    poziomu: {
                        tytul: "Vyberte úroveň studia",
                        poziom: "Vyberte úroveň studia, na které chcete studovat",
                        przycisk: {
                            powrot: "Zadní",
                            wybierz_tryb_studiow: "Vyberte způsob studia"
                        }
                    },
                    trybu: {
                        tytul: "Výběr režimu",
                        tryb: "Chcete-li pokračovat, musíte vybrat způsob studia",
                        przycisk: {
                            powrot: "Zadní",
                            podsumowanie: "další"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Informace ke smlouvě",
                    wybrane_studia: {
                        tytul: "Vybrané studijní programy",
                        poziom: "Úroveň studia",
                        tryb: "Režim",
                        kierunek: "Obor studia",
                        specjalnosc: "Hlavní, důležitý",
                        miasto: "Město",
                    },
                    obywatelstwo: {
                        tytul: "Občanství",
                        polskie: "polština",
                        obcokrajowiec: "Cizinec"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "Máte číslo PESEL?",
                            tak: "Ano",
                            nie: "Ne"
                        },
                        rodzaj_dowodu: {
                            tytul : "typ průkazu totožnosti",
                            dowod_osobisty: "průkaz totožnosti",
                            paszport: "pas",
                            karta_pobytu: "pobytová karta",
                            polski_dokument_podrozy: "polský cestovní doklad pro cizince",
                            tymczasowy_polski_dokument_podrozy: "dočasný polský cestovní doklad pro cizince",
                            polski_dokument_tozsamosci_cudzoziemca: "Polský doklad totožnosti cizince"
                        },
                        seria_numer_dowodu : "série a číslo",
                        walidacja: {
                            rodzaj_dowodu: "Uveďte typ důkazu",
                            seria_numer_dowodu: "Zadejte sérii a číslo důkazu"
                        }
                    },
                    m_legitymacja: {
                        tytul: "elektronický studentský průkaz (mLegitymacja)",
                        wydanie: "Chci elektronický studentský průkaz (ELS)."
                    },
                    dane_osobowe: {
                        tytul: "osobní data",
                        imie: "jméno",
                        imie_drugie: "prostřední jméno",
                        nazwisko: "příjmení",
                        pesel: "číslo PESEL",
                        data_ur: "datum narození",
                        miejsce_ur: "místo narození",
                        plec: {
                            tytul: "sex",
                            kobieta: "ženský",
                            mezczyzna: "mužský"
                        },
                        obywatelstwo: "občanství",
                        kraj_pochodzenia: "země původu",
                        walidacja: {
                            imie: "Vaše jméno",
                            nazwisko: "Vaše příjmení ",
                            pesel: "Vaše číslo PESEL",
                            pesel_bledny: "Špatné číslo PESEL",
                            data_ur: "Tvoje datum narození",
                            miejsce_ur: "Vaše místo narození",
                            plec: "Váš sex",
                            obywatelstwo: "Vaše občanství",
                            kraj_pochodzenia: "Vaše země původu"
                        }
                    },
                    adres: {
                        tytul_zam: "Adresa bydliště",
                        tytul_kor: "Korespondenční adresa",
                        wybor: {
                            taki_sam: "Stejná jako adresa bydliště",
                            inny: "jiný"
                        },
                        ulica: "ulice",
                        kod_pocztowy: "poštovní směrovací číslo",
                        miasto: "město",
                        rodzaj_miejscowosci: {
                            tytul: "typ města",
                            miasto: "město",
                            wies: "vesnice"
                        },
                        kraj: "země",
                        wojewodztwo: "vojvodství",
                        walidacja: {
                            ulica: "Vaše adresa",
                            kod_pocztowy: "Vaše PSČ",
                            miasto: "Vaše město/město",
                            rodzaj_miejscowosci: "Zadejte typ města",
                            wojewodztwo: "Vaše vojvodství",
                            kraj: "Vaše země"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "kontaktní údaje",
                        email: "emailová adresa",
                        telefon: "Číslo mobilního telefonu",
                        walidacja: {
                            email: "Vložte svou e-mailovou adresu",
                            telefon: "Zadejte číslo svého mobilního telefonu"
                        }
                    },
                    formularze: {
                        walidacja: "Vyberte jednu z možností"
                    },
                    regulamin:{
                        tytul: "Akceptace studijního řádu",
                        akceptuj_wszystko: "Přijměte vše",
                        wyrazenie_zgody: "Souhlas s podmínkami studijního řádu Collegium Humanum a pravidly pro zpracování a použití osobních údajů.",
                        walidacja: {
                            wszystkie: "Pro přechod na další část je potřeba správně vyplnit celý formulář.",
                            pojedynczy: "Chcete-li přejít na další část, musíte souhlas přijmout."
                        }
                    },
                    przycisk: {
                        pokaz: "ukázat",
                        pokaz_szczegoly: "ukázat detaily",
                        ukryj_szczegoly: "skrýt detaily",
                        wstecz: "zadní",
                        zakoncz: "Uložte a dokončete nábor"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Uchazeč byl již dříve zaregistrován pro vybranou nabídku"
                    }
                }
            },
        },
		uz: {
            translation: {
                brak_naborow: "O'qish yo'q",
                layout: {
                    tytul: "Elektron ishga qabul qilish",
                    wybrany: {
                        kierunek: "Tanlangan yo'nalish",
                        specjalnosc: "Tanlangan mutaxassislik",
                        poziom: "Tanlangan daraja",
                        tryb: "Tanlangan rejim",
                        miasto: "Tanlangan shahar"
                    },
                    pomoc: "Yordam kerak?",
                    zadzwon: "Bizga qo'ng'iroq qiling",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "O'qishni tanlash",
                        studia_lic_mgr: "Bakalavr va magistratura bosqichida o'qish",
                        studia_podyplomowe: "MBA, DBA, LL.M, MSc mutaxassisliklari bo'yicha aspirantura",
                        studia_podyplomowe_inne: "Boshqa aspirantura",
                        przycisk: {
                            wybierz_miasto: "Shaharni tanlang",
                            wybierz_specjalnosc: "Mutaxassisligingizni tanlang"
                        }
                    },
                    specjalnosci: {
                        tytul: "Mutaxassislikni tanlash",
                        wyszukiwarka: "Mutaxassislikni toping",
                        specjalnosc: "Mutaxassisligingizni tanlang",
                        przycisk: {
                            powrot: "Orqaga",
                            wybierz_miasto: "Shaharni tanlang"
                        }
                    },
                    miasta: {
                        tytul: "O'qish joyini tanlash",
                        miasto: "O'qishni xohlagan shaharni tanlang",
                        przycisk: {
                            powrot: "Orqaga",
                            wybierz_poziom_studiow: "O'qish darajasini tanlang"
                        }
                    },
                    poziomu: {
                        tytul: "O'qish darajasini tanlang",
                        poziom: "O'qishni xohlagan ta'lim darajasini tanlang",
                        przycisk: {
                            powrot: "Orqaga",
                            wybierz_tryb_studiow: "O'qish rejimini tanlang"
                        }
                    },
                    trybu: {
                        tytul: "Rejim tanlash",
                        tryb: "Davom etish uchun siz o'qish rejimini tanlashingiz kerak",
                        przycisk: {
                            powrot: "Orqaga",
                            podsumowanie: "Keyingisi"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Shartnoma uchun ma'lumot",
                    wybrane_studia: {
                        tytul: "Tanlangan o'quv dasturlari",
                        poziom: "O'qish darajasi",
                        tryb: "Rejim",
                        kierunek: "O'quv sohasi",
                        specjalnosc: "mayor",
                        miasto: "Shahar",
                    },
                    obywatelstwo: {
                        tytul: "Fuqarolik",
                        polskie: "polyak",
                        obcokrajowiec: "Chet ellik"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "PESEL nomeringiz bormi?",
                            tak: "Ha",
                            nie: "YO'Q"
                        },
                        rodzaj_dowodu: {
                            tytul : "ID karta turi",
                            dowod_osobisty: "ID karta",
                            paszport: "pasport",
                            karta_pobytu: "yashash kartasi",
                            polski_dokument_podrozy: "Chet ellik uchun Polsha sayohat hujjati",
                            tymczasowy_polski_dokument_podrozy: "chet ellik uchun vaqtinchalik Polsha sayohat hujjati",
                            polski_dokument_tozsamosci_cudzoziemca: "Polsha chet el fuqarosining shaxsini tasdiqlovchi hujjat"
                        },
                        seria_numer_dowodu : "seriya va raqam",
                        walidacja: {
                            rodzaj_dowodu: "Dalillar turini ko'rsating",
                            seria_numer_dowodu: "Seriya va ID raqamini ko'rsating"
                        }
                    },
                    m_legitymacja: {
                        tytul: "elektron talaba identifikatori (mLegitymacja)",
                        wydanie: "Menga elektron talaba guvohnomasi (ELS) kerak."
                    },
                    dane_osobowe: {
                        tytul: "Shaxsiy malumot",
                        imie: "ism",
                        imie_drugie: "otasini ismi",
                        nazwisko: "familiya",
                        pesel: "PESEL raqami",
                        data_ur: "tug'ilgan kuni",
                        miejsce_ur: "tug'ilgan joy",
                        plec: {
                            tytul: "jinsiy aloqa",
                            kobieta: "ayol",
                            mezczyzna: "erkak"
                        },
                        obywatelstwo: "fuqarolik",
                        kraj_pochodzenia: "ishlab chiqaruvchi mamlakat; ta'minotchi mamlakat",
                        walidacja: {
                            imie: "Sizning ismingiz",
                            nazwisko: "Sizning familiyangiz ",
                            pesel: "Sizning PESEL raqamingiz",
                            pesel_bledny: "Noto'g'ri PESEL raqami",
                            data_ur: "Tug'ilgan kuningiz",
                            miejsce_ur: "Sizning tug'ilgan joyingiz",
                            plec: "Sizning jinsiy aloqangiz",
                            obywatelstwo: "Sizning fuqaroligingiz",
                            kraj_pochodzenia: "Sizning kelib chiqqan mamlakatingiz"
                        }
                    },
                    adres: {
                        tytul_zam: "Yashash manzili",
                        tytul_kor: "Xat yozish manzili",
                        wybor: {
                            taki_sam: "Yashash manzili bilan bir xil",
                            inny: "Boshqa"
                        },
                        ulica: "ko'cha",
                        kod_pocztowy: "Pochta kodi",
                        miasto: "shahar",
                        rodzaj_miejscowosci: {
                            tytul: "shahar turi",
                            miasto: "shahar",
                            wies: "qishloq"
                        },
                        kraj: "mamlakat",
                        wojewodztwo: "voevodelik",
                        walidacja: {
                            ulica: "Sizning manzilingiz",
                            kod_pocztowy: "Sizning pochta indeksingiz",
                            miasto: "Sizning shahringiz/shaharingiz",
                            rodzaj_miejscowosci: "Shahar turini kiriting",
                            wojewodztwo: "Sizning voevodligingiz",
                            kraj: "Sizning mamlakatingiz"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "aloqa tafsilotlari",
                        email: "E-pochta manzili",
                        telefon: "mobil telefon raqami",
                        walidacja: {
                            email: "E-mail manzilingizni kiriting",
                            telefon: "Mobil telefon raqamingizni kiriting"
                        }
                    },
                    formularze: {
                        walidacja: "Variantlardan birini tanlang"
                    },
                    regulamin:{
                        tytul: "O'qish qoidalarini qabul qilish",
                        akceptuj_wszystko: "Hamma narsani qabul qiling",
                        wyrazenie_zgody: "Collegium Humanum tadqiqot qoidalari va shaxsiy ma'lumotlarni qayta ishlash va ulardan foydalanish qoidalariga rozilik.",
                        walidacja: {
                            wszystkie: "Keyingi qismga o'tish uchun siz butun shaklni to'g'ri to'ldirishingiz kerak.",
                            pojedynczy: "Keyingi qismga o'tish uchun siz rozilikni qabul qilishingiz kerak."
                        }
                    },
                    przycisk: {
                        pokaz: "ko'rsatish",
                        pokaz_szczegoly: "tafsilotlarni ko'rsatish",
                        ukryj_szczegoly: "tafsilotlarni ko'rsatish",
                        wstecz: "orqaga",
                        zakoncz: "Saqlash va ishga yollashni yakunlash"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Nomzod tanlangan taklif uchun avval ro‘yxatdan o‘tgan"
                    }
                }
            },
        },
        de: {
            translation: {
                brak_naborow: "Kein Studium",
                layout: {
                    tytul: "Elektronische Personalbeschaffung",
                    wybrany: {
                        kierunek: "Ausgewählte Richtung",
                        specjalnosc: "Ausgewählte Spezialität",
                        poziom: "Ausgewählte Ebene",
                        tryb: "Ausgewählter Modus",
                        miasto: "Ausgewählte Stadt"
                    },
                    pomoc: "Brauchen Sie Hilfe?",
                    zadzwon: "Rufen Sie uns an",
                    telefon: {
                        kierunkowy_miedzynarodowy: "+48",
                        numer: "22 4875359"
                    }
                },
                wybor: {
                    studiow: {
                        tytul: "Studienwahl",
                        studia_lic_mgr: "Bachelor- und Masterstudiengänge",
                        studia_podyplomowe: "Aufbaustudium MBA, DBA, LL.M, MSc, mit Spezialisierung",
                        studia_podyplomowe_inne: "Andere Aufbaustudiengänge",
                        przycisk: {
                            wybierz_miasto: "Wählen Sie die Stadt aus",
                            wybierz_specjalnosc: "Wählen Sie Ihre Spezialisierung"
                        }
                    },
                    specjalnosci: {
                        tytul: "Spezialitätenauswahl",
                        wyszukiwarka: "Spezialisierung finden",
                        specjalnosc: "Wählen Sie Ihre Spezialisierung",
                        przycisk: {
                            powrot: "Zurück",
                            wybierz_miasto: "Wählen Sie die Stadt"
                        }
                    },
                    miasta: {
                        tytul: "Wahl eines Studienortes",
                        miasto: "Wählen Sie die Stadt aus, in der Sie studieren möchten",
                        przycisk: {
                            powrot: "Zurück",
                            wybierz_poziom_studiow: "Wählen Sie die Studienstufe aus"
                        }
                    },
                    poziomu: {
                        tytul: "Wählen Sie die Studienstufe aus",
                        poziom: "Wählen Sie die Studienstufe aus, auf der Sie studieren möchten",
                        przycisk: {
                            powrot: "Zurück",
                            wybierz_tryb_studiow: "Wählen Sie den Studienmodus"
                        }
                    },
                    trybu: {
                        tytul: "Modusauswahl",
                        tryb: "Um fortzufahren, müssen Sie den Studienmodus auswählen",
                        przycisk: {
                            powrot: "Zurück",
                            podsumowanie: "Nächste"
                        }
                    },
                },
                podsumowanie: {
                    tytul: "Informationen zum Vertrag",
                    wybrane_studia: {
                        tytul: "Ausgewählte Studiengänge",
                        poziom: "Studienniveau",
                        tryb: "Modus",
                        kierunek: "Forschungsbereich",
                        specjalnosc: "Wesentlich",
                        miasto: "Stadt",
                    },
                    obywatelstwo: {
                        tytul: "Staatsbürgerschaft",
                        polskie: "Polieren",
                        obcokrajowiec: "Ausländer"
                    },
                    obcokrajowiec: {
                        pesel: {
                            nazwa: "Haben Sie eine PESEL-Nummer?",
                            tak: "Ja",
                            nie: "NEIN"
                        },
                        rodzaj_dowodu: {
                            tytul : "Art des Personalausweises",
                            dowod_osobisty: "Ausweis",
                            paszport: "Reisepass",
                            karta_pobytu: "Aufenthaltserlaubniskarte",
                            polski_dokument_podrozy: "Polnisches Reisedokument für einen Ausländer",
                            tymczasowy_polski_dokument_podrozy: "vorläufiges polnisches Reisedokument für einen Ausländer",
                            polski_dokument_tozsamosci_cudzoziemca: "Polnischer Ausländerausweis"
                        },
                        seria_numer_dowodu : "Serie und Nummer",
                        walidacja: {
                            rodzaj_dowodu: "Geben Sie die Art des Beweismittels an",
                            seria_numer_dowodu: "Geben Sie die Serien- und ID-Nummer an"
                        }
                    },
                    m_legitymacja: {
                        tytul: "elektronischer Studentenausweis (mLegitymacja)",
                        wydanie: "Ich möchte einen elektronischen Studierendenausweis (ELS)."
                    },
                    dane_osobowe: {
                        tytul: "persönliche Daten",
                        imie: "Vorname",
                        imie_drugie: "zweiter Vorname",
                        nazwisko: "Nachname",
                        pesel: "PESEL-Nummer",
                        data_ur: "Geburtsdatum",
                        miejsce_ur: "Geburtsort",
                        plec: {
                            tytul: "Sex",
                            kobieta: "weiblich",
                            mezczyzna: "männlich"
                        },
                        obywatelstwo: "Staatsbürgerschaft",
                        kraj_pochodzenia: "Herkunftsland",
                        walidacja: {
                            imie: "Ihr Vorname",
                            nazwisko: "Dein Familienname ",
                            pesel: "Ihre PESEL-Nummer",
                            pesel_bledny: "Falsche PESEL-Nummer",
                            data_ur: "Dein Geburtsdatum",
                            miejsce_ur: "Ihr Geburtsort",
                            plec: "Dein Geschlecht",
                            obywatelstwo: "Ihre Staatsbürgerschaft",
                            kraj_pochodzenia: "Ihr Herkunftsland"
                        }
                    },
                    adres: {
                        tytul_zam: "Adresse",
                        tytul_kor: "Korrespondenzadresse",
                        wybor: {
                            taki_sam: "Das Gleiche wie die Wohnadresse",
                            inny: "Andere"
                        },
                        ulica: "Straße",
                        kod_pocztowy: "Postleitzahl",
                        miasto: "Stadt",
                        rodzaj_miejscowosci: {
                            tytul: "Art der Stadt",
                            miasto: "Stadt",
                            wies: "Dorf"
                        },
                        kraj: "Land",
                        wojewodztwo: "Woiwodschaft",
                        walidacja: {
                            ulica: "Deine Adresse",
                            kod_pocztowy: "Ihre Postleitzahl",
                            miasto: "Ihre Stadt/Stadt",
                            rodzaj_miejscowosci: "Geben Sie die Art der Stadt ein",
                            wojewodztwo: "Ihre Woiwodschaft",
                            kraj: "Dein Land"
                        }
                    },
                    dane_kontaktowe: {
                        tytul: "Kontaktdetails",
                        email: "E-Mail-Adresse",
                        telefon: "Handynummer",
                        walidacja: {
                            email: "Geben sie ihre E-Mailadresse ein",
                            telefon: "Gib deine Handynummer ein"
                        }
                    },
                    formularze: {
                        walidacja: "Wählen Sie eine der Optionen"
                    },
                    regulamin:{
                        tytul: "Anerkennung der Studienordnung",
                        akceptuj_wszystko: "Akzeptiere alles",
                        wyrazenie_zgody: "Zustimmung zu den Bestimmungen der Studienordnung des Collegium Humanum und den Regeln zur Verarbeitung und Nutzung personenbezogener Daten.",
                        walidacja: {
                            wszystkie: "Um zum nächsten Teil zu gelangen, müssen Sie das gesamte Formular korrekt ausfüllen.",
                            pojedynczy: "Um zum nächsten Teil zu gelangen, müssen Sie die Einwilligung akzeptieren."
                        }
                    },
                    przycisk: {
                        pokaz: "zeigen",
                        pokaz_szczegoly: "zeige Details",
                        ukryj_szczegoly: "Details ausblenden",
                        wstecz: "zurück",
                        zakoncz: "Speichern Sie die Rekrutierung und schließen Sie sie ab"
                    },
                    toast: {
                        kandydat_zapisany_wczesniej: "Der Kandidat wurde zuvor für das ausgewählte Angebot registriert"
                    }
                }
            },
        },
    },
});

export default {i18n};
