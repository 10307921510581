import definicje from './DefinicjePrzeplywow'

export const wyznaczPrzyciskPrzod = (przeplywy, numerAktualnejStrony) => {

    if(przeplywy.aktualnyPrzeplywNazwa == null) return {path: null, nazwa: null}

    let defAktualnegoPrzeplywu = wyciagnijDefinicjePrzeplywu(przeplywy.aktualnyPrzeplywNazwa)
    let defAktualnejStrony = wyciagnijDefinicjeStrony(defAktualnegoPrzeplywu, numerAktualnejStrony)

    if(czyWarunkiZawieraja(defAktualnejStrony.warunki,"OSTATNIA")){
        return {path: "/podsumowanie", nazwa: "wybor.trybu.przycisk.podsumowanie"}
    }

     let defNastepnejStrony = wyciagnijDefinicjeStrony(defAktualnegoPrzeplywu, numerAktualnejStrony+1)

    if(czyWarunkiZawieraja(defNastepnejStrony.warunki,"POMIJALNA_SPECJALIZACJA")){
        let dksy = przeplywy.historiaWyborow.get(numerAktualnejStrony)
        if(dksy[0].kierunekSpecjalnosc.specjalnosc.id == null){
            return wyznaczPrzyciskPrzod(przeplywy, numerAktualnejStrony+1)
        }
    }

    if(czyWarunkiZawieraja(defNastepnejStrony.warunki,"POMIJALNE_MIASTO")){
        if(!przeplywy.historiaWyborow.has(numerAktualnejStrony)) return {path: null, nazwa: null}
        let nazwy = przeplywy.historiaWyborow.get(numerAktualnejStrony)
        .map(item => item.kierunekSpecjalnosc.wydzial.nazwa)
        let nazwyBezPowt = nazwy.filter((item, index) => nazwy.indexOf(item) === index)

        if(nazwyBezPowt.length === 1){
            return wyznaczPrzyciskPrzod(przeplywy, numerAktualnejStrony+1)
        }
    }

 return {path: defNastepnejStrony.path, nazwa: defNastepnejStrony.nazwaPrzyciskuPrzod}
}

export const  wyznaczPrzyciskTyl = (przeplywy, numerStrony) =>{
   if(numerStrony <= 1) return {path: "/", nazwa: null}

   let historiaWyborow = przeplywy.historiaWyborow
   if(!historiaWyborow.has(numerStrony-1)){
   return wyznaczPrzyciskTyl(przeplywy,numerStrony-1)
   }else{
       let defAktualnegoPrzeplywu = wyciagnijDefinicjePrzeplywu(przeplywy.aktualnyPrzeplywNazwa)
       let defPoprzedniejStrony = wyciagnijDefinicjeStrony(defAktualnegoPrzeplywu, numerStrony-1)
       return {path: defPoprzedniejStrony.path, nazwa: null}
   }
}

export const  wyznaczWybranaNazweKierunku = (przeplywy, numerStrony) => {
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return null;
    let dksy = historiaWyborow.get(numerStrony)
    if(dksy == null) return null
    return dksy[0].kierunekSpecjalnosc.kierunek.nazwa
}

export const wyznaczWybranaNazweSpecjalnosci = (przeplywy, numerStrony) =>{
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return null
    let dksy = historiaWyborow.get(numerStrony)
    if(dksy == null) return null
    return dksy[0].kierunekSpecjalnosc.specjalnosc.nazwa
}
export const wyznaczWybranaNazweMiasta = (przeplywy, numerStrony) =>{
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return null
    let dksy = historiaWyborow.get(numerStrony)
    if(dksy == null) return null
    return dksy[0].kierunekSpecjalnosc.wydzial.nazwa
}
export const wyznaczWybranaNazwePoziomu = (przeplywy, numerStrony) =>{
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return null
    let dksy = historiaWyborow.get(numerStrony)
    if(dksy == null) return null
    return dksy[0].kierunekSpecjalnosc.stopienStudiow.nazwa
}
export const wyznaczWybranaNazweTrybu = (przeplywy, numerStrony) =>{
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return null
    let dksy = historiaWyborow.get(numerStrony)
    if(dksy == null) return null
    return dksy[0].kierunekSpecjalnosc.trybStudiow.nazwa
}
export const zapiszWybor = (przeplywy, setPrzeplywy, wybraneDksy, numerStrony) => {
 zapiszWyborOrazAktualnyPrzeplyw(przeplywy, setPrzeplywy, wybraneDksy, przeplywy.aktualnyPrzeplywNazwa, numerStrony)
}

export const zapiszWyborOrazAktualnyPrzeplyw = (przeplywy, setPrzeplywy, wybraneDksy, aktualnyPrzeplywNazwa, numerStrony) => {
    let defAktualnegoPrzeplywu = wyciagnijDefinicjePrzeplywu(aktualnyPrzeplywNazwa)

    let mapa = przeplywy.historiaWyborow;
    mapa.set(numerStrony,wybraneDksy)

    setPrzeplywy({
        aktualnyPrzeplywNazwa: aktualnyPrzeplywNazwa,
        historiaWyborow: mapa
        });
}

export const wyznaczNumerStrony = (przeplywy, idStrony) =>{
let defAktualnegoPrzeplywu = wyciagnijDefinicjePrzeplywu(przeplywy.aktualnyPrzeplywNazwa)
let defAktualnejStrony = wyciagnijDefinicjeStronyWgIdStrony(defAktualnegoPrzeplywu, idStrony)

return defAktualnejStrony.numerStrony;
}

export const wyznaczNumerStronyPodsumowanie = (przeplywy) =>{

    let ostatniaStrona = 1;
    przeplywy.historiaWyborow.forEach((val,key, map) => {
        if(key > ostatniaStrona){
        ostatniaStrona = key;
        }
        })
    return ostatniaStrona+1
}
export const wyznaczDksy = (przeplywy, numerStrony) => {
if(numerStrony < 1) return null
    let dksy = przeplywy.historiaWyborow.get(numerStrony)
    if (dksy == null) return wyznaczDksy(przeplywy, numerStrony-1)
    return dksy;
}

export const czyDokonanoWyboru = (przeplywy, numerStrony) => {
    let historiaWyborow = przeplywy.historiaWyborow
    if(!historiaWyborow.has(numerStrony)) return false;
    return true;
}
export const historiaWyczyscPrzyszlosc = (przeplywy, numerStrony) => {
    przeplywy.historiaWyborow.forEach((val,key, map) => {
        if(key > numerStrony){
        map.delete(key)
        }
    });
}

export const wyznaczWybranaNazweKierunkuNaPodstawieIdStrony = (przeplywy) => {
 let dks =  wyznaczWybranyDks(przeplywy, "ID_KIERUNEK");
 if(dks == null) return null;
 return dks.kierunekSpecjalnosc.kierunek.nazwa
}

export const wyznaczWybranaNazweSpecjalnosciNaPodstawieIdStrony = (przeplywy) => {
 let dks =  wyznaczWybranyDks(przeplywy, "ID_SPECJALNOSC");
 if(dks == null || dks.kierunekSpecjalnosc.specjalnosc == null) return null;
 return dks.kierunekSpecjalnosc.specjalnosc.nazwa
}

export const wyznaczWybranaNazweMiastaNaPodstawieIdStrony = (przeplywy) => {
 let dks =  wyznaczWybranyDks(przeplywy, "ID_MIASTO");
 if(dks == null) return null;
 return dks.kierunekSpecjalnosc.wydzial.nazwa
}

export const wyznaczWybranaNazwePoziomuNaPodstawieIdStrony = (przeplywy) => {
 let dks =  wyznaczWybranyDks(przeplywy, "ID_POZIOM");
 if(dks == null) return null;
 return dks.kierunekSpecjalnosc.stopienStudiow.nazwa
}

export const wyznaczWybranaNazweTrybuNaPodstawieIdStrony = (przeplywy) => {
 let dks =  wyznaczWybranyDks(przeplywy, "ID_TRYB");
 if(dks == null) return null;
 return dks.kierunekSpecjalnosc.trybStudiow.nazwa
}

const wyznaczWybranyDks = (przeplywy, idStrony) => {

    if (przeplywy.aktualnyPrzeplywNazwa == null) return null;

    let defAktualnegoPrzeplywu = wyciagnijDefinicjePrzeplywu(przeplywy.aktualnyPrzeplywNazwa)
    let defAktualnejStrony = wyciagnijDefinicjeStronyWgIdStrony(defAktualnegoPrzeplywu, idStrony)
         if(defAktualnejStrony == null) return null;
    let numerStrony = defAktualnejStrony.numerStrony
    if(!przeplywy.historiaWyborow.has(numerStrony)) return null;

    let dks = przeplywy.historiaWyborow.get(numerStrony);

    if(dks == null || dks[0] == null || dks[0].kierunekSpecjalnosc == null){
        return null;
    }

    return dks[0];
}

const wyciagnijDefinicjePrzeplywu = (aktualnyPrzeplyw) => {
    return definicje.find(item => item.nazwa === aktualnyPrzeplyw).przeplyw
}

const wyciagnijDefinicjeStrony = (defAktualnegoPrzeplywu, numerAktualnejStrony) => {
    return defAktualnegoPrzeplywu.find(item => item.numerStrony === numerAktualnejStrony)
}

const wyciagnijDefinicjeStronyWgIdStrony = (defAktualnegoPrzeplywu, idStrony) => {
    return defAktualnegoPrzeplywu.find(item => item.idStrony === idStrony)
}

const czyWarunkiZawieraja = (warunki, warunek) => {
    return warunki.find(item => item === warunek) != null
}
