import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import WyborKierunku from "./pages/WyborKierunku";
import WyborSpecjalnosci from "./pages/WyborSpecjalnosci";
import WyborMiasta from "./pages/WyborMiasta";
import WyborPoziomu from "./pages/WyborPoziomu";
import WyborTrybu from "./pages/WyborTrybu";
import Podsumowanie from "./pages/Podsumowanie";
import Zakonczenie from "./pages/Zakonczenie";
import NoPage from "./pages/NoPage";
import { useState, useEffect } from "react";
import axios from 'axios';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-P4T4MP6'
}

TagManager.initialize(tagManagerArgs)

const App = ({dksy, promoLink}) => {

  const [studiaMenuRozwiniete, setStudiaMenuRozwiniete] = useState(true);
  const [podyplomoweMenuRozwiniete, setPodyplomoweMenuRozwiniete] = useState(false);
  const [podyplomoweSpecMenuRozwiniete, setPodyplomoweSpecMenuRozwiniete] = useState(false);
  const [przeplywy, setPrzeplywy] = useState({
    aktualnyPrzeplywNazwa: null,
    historiaWyborow: new Map()
  });

    const routesConfig = [
      {
        element: <Layout
            przeplywy = {przeplywy}
            setPrzeplywy = {setPrzeplywy}
            pokarzFlagi={true}
            dksy={dksy}/>,
        children: [
          {
            path: "/",
            element: <WyborKierunku
                dksy = {dksy}
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
                studiaMenuRozwiniete = {studiaMenuRozwiniete}
                setStudiaMenuRozwiniete = {setStudiaMenuRozwiniete}
                podyplomoweMenuRozwiniete ={podyplomoweMenuRozwiniete}
                setPodyplomoweMenuRozwiniete ={setPodyplomoweMenuRozwiniete}
                podyplomoweSpecMenuRozwiniete ={podyplomoweSpecMenuRozwiniete}
                setPodyplomoweSpecMenuRozwiniete = {setPodyplomoweSpecMenuRozwiniete}
            />,
          },
          {
            path: "*",
            element: <WyborKierunku
                dksy = {dksy}
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
                studiaMenuRozwiniete = {studiaMenuRozwiniete}
                setStudiaMenuRozwiniete = {setStudiaMenuRozwiniete}
                podyplomoweMenuRozwiniete ={podyplomoweMenuRozwiniete}
                setPodyplomoweMenuRozwiniete ={setPodyplomoweMenuRozwiniete}
                podyplomoweSpecMenuRozwiniete ={podyplomoweSpecMenuRozwiniete}
                setPodyplomoweSpecMenuRozwiniete = {setPodyplomoweSpecMenuRozwiniete}
            />,
          },
        ],
      },
      {
        element: <Layout przeplywy = {przeplywy} pokarzFlagi={false} dksy={dksy}/>,
        children: [
          {
            path: "wybor-specjalnosci",
            element: <WyborSpecjalnosci
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
            />,
          },
          {
            path: "wybor-miasta",
            element: <WyborMiasta
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
            />,
          },
          {
            path: "wybor-poziomu",
            element: <WyborPoziomu
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
            />,
          },
          {
            path: "wybor-trybu",
            element: <WyborTrybu
                przeplywy = {przeplywy}
                setPrzeplywy = {setPrzeplywy}
            />,
          },
          {
            path: "podsumowanie",
            element: <Podsumowanie
              przeplywy = {przeplywy}
              promoLink = {promoLink}
            />,
          },
        ]
      },
      {
        element: <Layout przeplywy = {przeplywy} pokarzFlagi={false} dksy={dksy}/>,
        children: [
            {
            path: "zakonczenie",
            element: <Zakonczenie/>,
          }
        ],
      },
    ];

    const router = createBrowserRouter(routesConfig);
  return (
    <RouterProvider router={router} />

  );
}

export default App;
