export default [
    {
    nazwa: "STUDIA_WYZSZE",
    przeplyw:
        [
            { numerStrony: 1, idStrony: "ID_KIERUNEK", warunki: [], path: "/", nazwaPrzyciskuPrzod: ""},
            { numerStrony: 2, idStrony: "ID_SPECJALNOSC", warunki: ["POMIJALNA_SPECJALIZACJA"], path: "/wybor-specjalnosci", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_specjalnosc"},
            { numerStrony: 3, idStrony: "ID_MIASTO", warunki: [], path: "/wybor-miasta", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_miasto"},
            { numerStrony: 4, idStrony: "ID_POZIOM", warunki: [], path: "/wybor-poziomu", nazwaPrzyciskuPrzod: "wybor.miasta.przycisk.wybierz_poziom_studiow"},
            { numerStrony: 5, idStrony: "ID_TRYB", warunki: ["OSTATNIA"], path: "/wybor-trybu", nazwaPrzyciskuPrzod: "wybor.poziomu.przycisk.wybierz_tryb_studiow"},
        ]
    },
    {
    nazwa: "PODYPLOMOWE",
    przeplyw:
        [
            { numerStrony: 1, idStrony: "ID_KIERUNEK", warunki: [], path: "/", nazwaPrzyciskuPrzod: ""},
            { numerStrony: 2, idStrony: "ID_SPECJALNOSC", warunki: ["POMIJALNA_SPECJALIZACJA"], path: "wybor-specjalnosci", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_specjalnosc"},
            { numerStrony: 3, idStrony: "ID_TRYB", warunki: [], path: "/wybor-trybu", nazwaPrzyciskuPrzod: "wybor.poziomu.przycisk.wybierz_tryb_studiow"},
            { numerStrony: 4, idStrony: "ID_MIASTO", warunki: ["OSTATNIA", "POMIJALNE_MIASTO"], path: "/wybor-miasta", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_miasto"},
        ]
    },
    {
    nazwa: "PODYPLOMOWE_SPEC",
    przeplyw:
        [
            { numerStrony: 1, idStrony: "ID_KIERUNEK", warunki: [], path: "/", nazwaPrzyciskuPrzod: ""},
            { numerStrony: 2, idStrony: "ID_SPECJALNOSC", warunki: ["POMIJALNA_SPECJALIZACJA"], path: "wybor-specjalnosci", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_specjalnosc"},
            { numerStrony: 3, idStrony: "ID_TRYB", warunki: [], path: "/wybor-trybu", nazwaPrzyciskuPrzod: "wybor.poziomu.przycisk.wybierz_tryb_studiow"},
            { numerStrony: 4, idStrony: "ID_MIASTO", warunki: ["OSTATNIA", "POMIJALNE_MIASTO"], path: "/wybor-miasta", nazwaPrzyciskuPrzod: "wybor.studiow.przycisk.wybierz_miasto"},
        ]
    }
    ];