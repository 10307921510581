export const  wyznaczElementDomyslny = (elementy) => {

    if (elementy == null ) return null;
    let aktualnyElement = elementy.find(item => item.domyslny == true)
    return aktualnyElement
}

export const  wyznaczElementyPozostale = (elementy) => {

    if (elementy == null ) return null;
    let aktualneElementy = elementy.filter(item => item.domyslny != true)
    return aktualneElementy
}


export const czyBlednaWartosc = (pole) =>{

    if(pole.min == null) return true;
    return pole.wartosc.length < pole.min;
    }