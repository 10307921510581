import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import axios from 'axios'
import Select from 'react-select'
import rest from '../../utils/RestClient'
import { czyBlednaWartosc } from '../../utils/PodsumowanieFunkcje'
import { wyciagnijPierwszyElement, wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym } from '../../utils/ListyFunkcje'


const PodsumowanieAdresKrajowy = ({slowniki, typAdresu, trybWalidacyjny}) => {

    const [kodPocztowy5cyfr, setKodPocztowy5cyfr] = useState("")
    const [kodPocztowyObiekt, setKodPocztowyObiekt] = useState({pobranyKodPocztowy: null, wartosc: null})
    const [numerDomu, setNumerDomu] = useState("")
    const [miasto, setMiasto] = useState(null)
    const [ulicaKandydata, setUlicaKandydata] = useState({wartosc: ""});
    let { i18n, t } = useTranslation();
    let lang = i18n.language;

//    const zredukujNaPodstawieWybranegoMiasta = (item) => {
//    if(miasto == null) return item;
//    let d = kodPocztowyObiekt?.wartosc?.find(it => it.miejscowosc  === miasto);
//    console.log(d)
//    }

    if(kodPocztowy5cyfr.length == 5 && kodPocztowy5cyfr != kodPocztowyObiekt.pobranyKodPocztowy){
        axios({
            method: 'get',
            url: rest+'kody-pocztowe/'+kodPocztowy5cyfr,
        })
        .then(response => {
            setKodPocztowyObiekt({pobranyKodPocztowy: kodPocztowy5cyfr, wartosc: response.data != null ? response.data : []})
        })
    }

    let miasta = (kodPocztowyObiekt.pobranyKodPocztowy != null) ? kodPocztowyObiekt.wartosc.map(item => item.miejscowosc) : [];
    let rodzajMiejscowosci = (kodPocztowyObiekt.pobranyKodPocztowy != null) ? kodPocztowyObiekt.wartosc.map(item => item.rodzajMiejscowosci) : [];
    let ulica = (kodPocztowyObiekt.pobranyKodPocztowy != null) ? kodPocztowyObiekt.wartosc.map(item => item.ulica) : [];
    let wojewodztwo = (kodPocztowyObiekt.pobranyKodPocztowy != null) ? kodPocztowyObiekt.wartosc.map(item => item.wojewodztwo) : [];

    return (
            <>
                <div className="row">
                    <div className="cell-md-4">
                        <div className="form-group">
                            <input
                                id = {typAdresu+"_kod_pocztowy"}
                                maxLength={5}
                                type="text"
                                placeholder={t("podsumowanie.adres.kod_pocztowy") + "*"}
                                className="inputstyle"
                                onChange={(event) => setKodPocztowy5cyfr(event.target.value)}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                            />
                        {(kodPocztowy5cyfr.length < 5 && trybWalidacyjny == true) &&
                            <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.kod_pocztowy")}
                            </span>
                        }
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="form-group">
                            <select
                            xdata-role="select"
                            id = {typAdresu+"_miasto"}
                            className="inputstyle"
                            disabled={kodPocztowyObiekt.wartosc == null || kodPocztowyObiekt.wartosc.length === 0}
                            onChange={(event) => setMiasto(event.target.value)}>
                                <option
                                    selected=
                                        {wyciagnijPierwszyElement(miasta) == null ?
                                        null
                                        :
                                        wyciagnijPierwszyElement(miasta)}
                                    disabled=""
                                    hidden="">
                                        {wyciagnijPierwszyElement(miasta) == null ?
                                        t("podsumowanie.adres.miasto") + "*"
                                        :
                                        wyciagnijPierwszyElement(miasta)}
                                </option>
                                {wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(miasta) &&
                                    <>
                                        {
                                            wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(miasta)
                                            .map(item => (<option value={item}>{item}</option>))
                                        }
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                    <div className="cell-md-4">
                        <div className="form-groupx">
                            <select
                            id = {typAdresu+"_rodzaj_miejscowosci"}
                            xdata-role="select"
                            className="inputstyle"
                            disabled={kodPocztowyObiekt.wartosc == null || kodPocztowyObiekt.wartosc.length === 0}>
                                <option
                                    selected=
                                        {wyciagnijPierwszyElement(rodzajMiejscowosci) == null ?
                                        null
                                        :
                                        wyciagnijPierwszyElement(rodzajMiejscowosci)}
                                    disabled=""
                                    hidden="">
                                        {wyciagnijPierwszyElement(rodzajMiejscowosci) == null ?
                                        t("podsumowanie.adres.rodzaj_miejscowosci.tytul") + "*"
                                        :
                                        wyciagnijPierwszyElement(rodzajMiejscowosci)}
                                </option>
                                {wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(rodzajMiejscowosci) &&
                                    <>
                                        {
                                            wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(rodzajMiejscowosci)
//                                            .filter(item => zredukujNaPodstawieWybranegoMiasta(item))
                                            .map(item => (<option value={item}>{item}</option>))
                                        }
                                    </>
                                }
                            </select>a
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell-md-6">
                        <div className="form-group">

                            <input type="text"
                                   onChange={(event) => setUlicaKandydata({wartosc: event.target.value, min: 3})}
                                   placeholder={t("podsumowanie.adres.ulica") + "*"}
                                   className="inputstyle"
                                   id = {typAdresu+"_ulica"}
                            />
                            {(ulicaKandydata.wartosc.length < 3 && trybWalidacyjny == true) &&
                                <span className="error_panel">
                                {t("podsumowanie.adres.walidacja.ulica")}
                            </span>
                            }


                        </div>
                    </div>

                    <div className="cell-md-6">
                        <div className="form-group">
                            <input type="text"
                                onChange={(event) => setNumerDomu({wartosc: event.target.value, min: 1})}
                                placeholder={t("podsumowanie.adres.numer_domu") + "*"}
                                className="inputstyle"
                                id = {typAdresu+"_numer_domu"}
                            />
                            {(numerDomu.length < 1 && trybWalidacyjny == true) &&
                                <span className="error_panel">
                                    {t("podsumowanie.adres.walidacja.numer_domu")}
                                </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="cell-md-12">
                        <div className="xform-group">

                            <select
                                d = {typAdresu+"_wojewodztwo"}
                                xdata-role="select"
                                className="inputstyle"
                                disabled={kodPocztowyObiekt.pobranyKodPocztowy == null || kodPocztowyObiekt.wartosc.length === 0}>
                                <option
                                    selected=
                                        {wyciagnijPierwszyElement(wojewodztwo) == null ?
                                        null
                                        :
                                        wyciagnijPierwszyElement(wojewodztwo)}
                                    disabled=""
                                    hidden="">
                                        {wyciagnijPierwszyElement(wojewodztwo) == null ?
                                        t("podsumowanie.adres.wojewodztwo") + "*"
                                        :
                                        wyciagnijPierwszyElement(wojewodztwo)}
                                </option>
                                {wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(wojewodztwo) &&
                                    <>
                                        {
                                            wyciagnijWszystkieElementyBezPowtorzenPoZaPierwszym(wojewodztwo)
                                            .map(item => (<option value={item}>{item}</option>))
                                        }
                                    </>
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </>
        )
}
export default PodsumowanieAdresKrajowy;
