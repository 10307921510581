import {Link} from 'react-router-dom';
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    czyDokonanoWyboru,
    historiaWyczyscPrzyszlosc,
    wyznaczDksy,
    wyznaczNumerStrony,
    wyznaczPrzyciskPrzod,
    wyznaczPrzyciskTyl,
    wyznaczWybranaNazweSpecjalnosci,
    zapiszWybor
} from "../utils/KalkulatorPrzeplywow"

const WyborSpecjalnosci = ({przeplywy, setPrzeplywy}) => {

    if(przeplywy.aktualnyPrzeplywNazwa == null) window.location.replace("/");

    const [query, setQuery] = useState(null);
    let { i18n, t } = useTranslation();
    const idStrony = "ID_SPECJALNOSC"
    let numerStrony = wyznaczNumerStrony(przeplywy, idStrony)
    historiaWyczyscPrzyszlosc(przeplywy, numerStrony)
    let dksy = wyznaczDksy(przeplywy, numerStrony-1)
    let przyciskPrzod = wyznaczPrzyciskPrzod(przeplywy, numerStrony)
    let przyciskTyl = wyznaczPrzyciskTyl(przeplywy, numerStrony)

    const filtrujDksyDoWyswietleniaWgQuery = (dksyLista) => {
      if(query == null){
        return dksyLista;
      }else{
        return dksyLista.filter(item => {
        let nazwa = item.nazwa;
        if (nazwa.toUpperCase().indexOf(query.toUpperCase()) > -1)
        {
          return true;
        }
        else return false;
        })
      }
    }

  const mapuj = (studia) => {

            let listaSplaszczona = studia
                .filter((item) => item.kierunekSpecjalnosc.specjalnosc.nazwa != null)
                .map((item) => item.kierunekSpecjalnosc.specjalnosc.nazwa);
            let mapa = new Map();
            let listaSplaszczonaBezPowtorzen = listaSplaszczona
                .filter((item, index) => listaSplaszczona.indexOf(item) === index)

            listaSplaszczonaBezPowtorzen.forEach(nazwa => {

                let vartosci = studia
                                .filter((it) => it.kierunekSpecjalnosc.specjalnosc.nazwa == nazwa);
                mapa.set(nazwa, vartosci);
                })

            let array = Array.from(mapa, ([nazwa, wartosc]) => ({ nazwa, wartosc }));

    return array;
    }

    let przemapowaneDksyBezQuery = mapuj(dksy);
    let przemapowaneDksy = filtrujDksyDoWyswietleniaWgQuery(przemapowaneDksyBezQuery);

    return (
        <>
            <div id="search">
                <img src={require("../images/flatlogowhite.png")} className="flatlogo"/>

                <div style={{clear: "both"}}>
                    <div className="title">{t("wybor.specjalnosci.tytul")}</div>
                </div>

                <div style={{clear: "both"}}></div>
                <br/>
                <div className="newline"></div>
                <div style={{width: "100", textAlign: "left"}}>
                    <div style={{display: "none"}}>Szukaj:</div>
                    <input
                        style={{width:"100%", height: "68px", textTransform: "uppercase", textAlign: "left", border: "none", borderRadius: "0px", paddingLeft: "45px", fontFamily: "'Overpass', sans-serif", color: "#99A5AC", fontSize: "13px", lineHeight: "20px"}}
                        placeholder={t("wybor.specjalnosci.wyszukiwarka")}
                        type="text"
                        id="myInputtext"
                        className="input-control text"
                        onChange={event => {setQuery(event.target.value)}}
                    />
                </div>


            {przemapowaneDksy.length > 0 &&
                <>
                    <h1 style={{textTransform: "uppercase"}}>{t("wybor.specjalnosci.specjalnosc")}:  </h1>
                    <div style={{clear: "both"}}></div>
                        <br/>
                        <div className="sel">
                        {przemapowaneDksy
                            .map(item => (

                                <div
                                    style={{paddingTop: "25px", paddingBottom: "25px"}}
                                    className={"radio-style2"}
                                    onClick={(event) => zapiszWybor(przeplywy, setPrzeplywy, item.wartosc, numerStrony)}
                                >
                                    <div className="dot">
                                    {item.nazwa == wyznaczWybranaNazweSpecjalnosci(przeplywy, numerStrony) &&
                                        <div className="inner-dot"/>
                                    }
                                    </div>
                                    <div className="caption" style ={{width: "95%", float: "right"}}>
                                   {item.nazwa}
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </>
                }

                <Link to={przyciskTyl.path} style={{ textDecoration: 'none' }}>
                    <div id="myid" style={{display: "block", marginTop: "20px"}}>
                        <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                            <button style={{width: "100%"}} className="buttongrey">{t("wybor.specjalnosci.przycisk.powrot")}</button>
                        </div>
                    </div>
                </Link>
                {czyDokonanoWyboru(przeplywy, numerStrony) &&
                        <Link to={przyciskPrzod.path} style={{ textDecoration: 'none' }}>
                            <div id="myid" style={{display: "block", marginTop: "20px"}}>
                                <div style={{textAlign: "center", width: "100%", clear: "both"}}>
                                    <button style={{width: "100%"}} className="buttongreen">
                                    {t(przyciskPrzod.nazwa)}
                                    </button>
                                </div>
                            </div>
                        </Link>
                 }
                </div>
        </>
    );
};

export default WyborSpecjalnosci;
