import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import rest from '../utils/RestClient';
const Zakonczenie = () => {

    const [templatka, setTemplatka] = useState(" ");
    let { i18n, t } = useTranslation();
    let lang = i18n.language;


            Promise.all([
              fetch(rest+'templatki-html/zakonczenie-rekrutacji'+ '?lang=' + lang)
            ])
            .then(([resTemplatka]) =>
                    Promise.all([resTemplatka.json()])
                  )
            .then(([templatka]) => {
                setTemplatka(templatka.wartosc);
                })

 return (
<div id="search">
<div dangerouslySetInnerHTML={{__html: templatka}}></div>
</div>
)
}
export default Zakonczenie;